// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** colors for color coding */
/** red */
/** orange */
/** green */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 989px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1350px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 989px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1350px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.text-monospace {
  font-family: "Monospace", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 989px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1350px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #FF0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b30000 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(40, 40, 40, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

:host::ng-deep .col-left {
  border-right: 1px solid #f0f0f0;
}
@media (max-width: 767.98px) {
  :host::ng-deep .col-left {
    border-right: 0 !important;
  }
}
:host::ng-deep .col-left,
:host::ng-deep .col-right {
  margin-top: 32px;
}
:host::ng-deep .cur {
  cursor: pointer;
}
:host::ng-deep .text-flow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
:host::ng-deep .padding-right-20 {
  padding-right: 20px;
}
:host::ng-deep .accordion-popup {
  max-height: 400px;
  overflow-y: auto;
  width: 230px;
  height: auto;
  padding: 10px;
  box-shadow: 0 2px 4px 0 #282828;
  background-color: #ffffff;
  color: #282828;
  position: absolute;
  left: 50%;
  z-index: 100;
  top: 75px;
  cursor: pointer;
}
:host::ng-deep .accordion-popup-for-actual-delivery {
  margin-left: -25px;
}
:host::ng-deep .accordion-popup .ranges-arrow {
  width: 20px;
  height: 18px;
  top: 1px;
  left: -5px;
  position: relative;
}
:host::ng-deep .mat-mdc-list {
  padding: 0;
  margin-top: 32px;
  letter-spacing: 0.4;
}
:host::ng-deep .mat-mdc-list:first-child {
  margin-top: 0;
}
:host::ng-deep .mat-mdc-list-item {
  height: auto;
}
:host::ng-deep .mat-mdc-list-item.showmore {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
}
:host::ng-deep .mat-mdc-list-item.showmore .mdc-list-item__content {
  border-bottom: 0 !important;
}
:host::ng-deep .mat-mdc-list-item.hide {
  display: none !important;
}
:host::ng-deep .mat-mdc-list-item.multi {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
}
:host::ng-deep .mat-mdc-list-item.multi .mdc-list-item__content {
  display: block;
}
:host::ng-deep .mat-mdc-list-item.list-delivery {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
}
:host::ng-deep .mat-mdc-list-item.list-delivery .mdc-list-item__content .list-left {
  width: 40%;
  display: inline;
}
:host::ng-deep .mat-mdc-list-item.list-delivery .mdc-list-item__content .list-right {
  width: 60%;
  display: inline-flex;
  justify-content: space-around;
}
:host::ng-deep .mat-mdc-list-item-ripple {
  display: none;
}
:host::ng-deep .mat-mdc-list-item::ng-deep {
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content {
  justify-content: space-between;
  color: #888888;
  border-bottom: 1px solid #f0f0f0;
  padding: 6px 0;
  margin: 0;
  line-height: 24px;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value > span {
  padding: 0;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value .each-port-region {
  border-bottom: 1px solid #f0f0f0;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value .each-port-region .each-port {
  border-top: 1px solid #f0f0f0;
  margin-left: 18px;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value-change {
  float: right;
  width: 27% !important;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value-changedate {
  float: right;
  width: 27% !important;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value-icon {
  width: 11% !important;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value-name {
  width: 59% !important;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #282828;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value .start-date,
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value .end-date {
  display: inline-block !important;
  width: 41% !important;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value .blackout-comment {
  display: inline-block;
  width: 11%;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .value .pad-to {
  display: inline-block;
  width: 7%;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .rate-row {
  width: 100%;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .row {
  margin: 0;
  width: 100%;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .row > span {
  padding: 0;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .row > span:last-child {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .list-left span {
  padding-right: 5px;
}
:host::ng-deep .mat-mdc-list-item::ng-deep .mdc-list-item__content .comment-icon img {
  height: 16px;
  position: relative;
  margin-left: 1px;
  top: 4px;
}
:host::ng-deep .mat-mdc-list .mat-mdc-subheader {
  color: #888888;
  border-bottom: 1px solid #f0f0f0;
  padding: 6px 0;
  margin: 0;
  height: auto;
}
:host::ng-deep .mat-mdc-list .mat-mdc-subheader .confidential {
  text-align: right;
  color: #ff9b1e;
  font-family: "MaerskText", sans-serif;
  font-weight: bold;
}
:host::ng-deep .show-more-icon-fixture {
  position: absolute;
  top: 3px;
}
:host::ng-deep .show-more-icon-fixture img {
  height: 15px;
  width: 17px;
}
:host::ng-deep .port-number {
  padding-left: 8px;
  color: #282828;
}
:host::ng-deep .port-div {
  border-left: 4px solid #f0f0f0;
  width: 50%;
  display: inline-block;
}
:host::ng-deep .port-space {
  width: 50%;
  display: inline-block;
}
:host::ng-deep .comment-popup,
:host::ng-deep .div-popup {
  padding: 10px;
  border: 1px solid #ffd21e;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 2222;
  position: absolute;
  margin-top: 0;
  opacity: 1;
  background-color: #fff6d2;
  color: #282828;
}
:host::ng-deep .div-popup {
  width: 240px;
  height: auto;
  margin-left: -32px;
}
:host::ng-deep .div-option-popup {
  width: 400px !important;
}
:host::ng-deep .comment-popup {
  width: 260px;
  height: auto;
  right: 0;
}
:host::ng-deep .time-heading {
  width: 40%;
  display: inline-block;
}
:host::ng-deep .time-value {
  width: 60%;
  display: inline-block;
}
:host::ng-deep .margin-top-5 {
  margin-top: 5px;
}
:host::ng-deep .text-yellow {
  color: #ff9b1e;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
