//

import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpSpinnerToggleService } from './services/http-spinner-toggle/http-spinner-toggle.service';
import { TradeListService } from './services/trade-list/trade-list.service';
import { BuildYardService } from './services/build-yard/build-yard.service';
import { CargoRestrictionGroupService } from './services/cargo-restriction-group/cargo-restriction-group.service';
import { IceClassService } from './services/ice-class/ice-class.service';
import { PaintsService } from './services/paints/paints.service';
import { PortService } from './services/port/port.service';
import { CountryService } from './services/country/country.service';
import { VesselDropdownsService } from './services/vessel-dropdowns/vessel-dropdowns.service';
import { DropdownContentService } from './services/dropdown-content/dropdown-content.service';
import { VesselsService } from './services/vessels/vessels.service';
import { AlertService } from './services/alert/alert.service';

import { HttpSpinnerInterceptor } from './interceptors/http-spinner.interceptor';
import { NoCacheInterceptor } from './interceptors/no-cache.interceptor';
import { SAPMaterialsService } from './services/SAPMaterials/sapmaterials.service';
import { FixturesService } from '../modules/fixtures/services/fixtures/fixtures.service';
import { SelfService } from '@cham-services/self-service/self-service.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    DropdownContentService,
    VesselsService,
    VesselDropdownsService,
    CountryService,
    PortService,
    PaintsService,
    IceClassService,
    BuildYardService,
    CargoRestrictionGroupService,
    TradeListService,
    SAPMaterialsService,
    SelfService,
    HttpSpinnerToggleService,
    FixturesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [AlertService]
    };
  }

}
