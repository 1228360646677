// Recap & comments Tab Section
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@cham-services/alert/alert.service';
import { SelfService } from '@cham-services/self-service/self-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import cronstrue from 'cronstrue';
import { AuthService } from '@cham-services/authentication/auth/auth.service';
@Component({
  selector: 'champs-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss'],
})
export class SelfServiceComponent implements OnInit {
  public rows: Array<any> = [];
  public showElementsCount = 50;
  public showError: boolean = false;
  public jobRunFormGrp: FormGroup;
  private dialogRef: MatDialogRef<any>;

  @ViewChild('jobRunFormTempate', { static: false }) public jobRunFormTempate: any;

  constructor(
    private selfService: SelfService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private alert: AlertService,
    private auth: AuthService
  ) {}

  public ngOnInit() {
    this.jobRunFormGrp = this.formBuilder.group({
      jobId: null,
      jobName: '',
      jobRunDay: [1, [Validators.required, Validators.min(1), Validators.max(31), Validators.pattern('^[0-9]*$')]],
    });

    this.getJobs();
  }

  public getJobs() {
    this.selfService.getJobs().subscribe((res) => {
      this.rows = res;
      this.spinner.hide();
    });
  }

  public showMore() {
    this.showElementsCount = this.showElementsCount + 50;
  }

  public canUpdateJob(rowData: any) {
    const isAuthorised = this.auth.isAuthorised(this.auth.roles, 'SelfService', 'update');
    const cronExpressionArray = rowData.cronExpression.split(' ');

    return !cronExpressionArray[2].includes('*') && isAuthorised;
  }

  public openJobForm(rowData: any) {
    this.jobRunFormGrp.patchValue({
      jobId: rowData.jobID,
      jobName: rowData.displayName,
      jobRunDay: rowData.jobRunDay,
    });

    if (!this.canUpdateJob(rowData)) {
      return;
    }

    this.dialogRef = this.dialog.open(this.jobRunFormTempate, {
      width: '500px',
      panelClass: 'dialog-container',
      disableClose: true,
    });
  }

  public submitJobRunForm() {
    if (this.jobRunFormGrp.invalid) {
      return;
    }
    let cronDayNumber: number;
    const job = this.rows.find((x) => x.jobID === this.jobRunFormGrp.value.jobId);
    const cronExpressionArray = job.cronExpression.split(' ');

    if (cronExpressionArray[2].includes('-')) {
      cronDayNumber = Number(cronExpressionArray[2].split('-')[1]);
    } else {
      cronDayNumber = Number(cronExpressionArray[2]);
    }

    if (isNaN(cronDayNumber) || this.jobRunFormGrp.value.jobRunDay > cronDayNumber) {
      this.showError = true;
      return;
    }

    cronExpressionArray[2] = this.jobRunFormGrp.value.jobRunDay.toString();
    const updatedCronExpression = cronExpressionArray.join(' ');
    this.selfService.updateCronExpression(job.jobID, updatedCronExpression).subscribe(
      (res) => {
        this.alert.clear();
        this.alert.success('Job updated successfully');
        this.getJobs();
        this.dialogRef.close();
      },
      (err) => {
        this.alert.clear();
        this.alert.error('Failed to update job:');
      },
      () => {
        this.showError = false;
      }
    );
  }

  public resetJob(row) {
    this.selfService.updateCronExpression(row.jobID, '').subscribe(
      (res) => {
        this.alert.clear();
        this.alert.success('Job updated successfully');
        this.getJobs();
      },
      (err) => {
        this.alert.clear();
        this.alert.error('Failed to update job:');
      },
      () => {
        this.showError = false;
      }
    );
  }

  public getCronDescription(cronExpression: string): string {
    try {
      return cronExpression && cronstrue.toString(cronExpression);
    } catch (e) {
      return cronExpression;
    }
  }
}
