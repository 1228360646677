// reusable icon component, mainly used for deployment communication
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
@Component({
  selector: 'champs-deployment-communication-icon',
  templateUrl: './deployment-communication-icon.component.html',
  styleUrls: ['./deployment-communication-icon.component.scss'],
})
export class DeploymentCommunicationIconComponent implements OnInit {
  private dialogRef: MatDialogRef<any>;
  @Input() public status;
  @Input() public pkid;
  @Input() public depEndDate;
  @ViewChild('changeStatusConfirm', { static: false }) public changeStatusConfirm: any;
  @ViewChild('revertStatusConfirm', { static: false }) public revertStatusConfirm: any;

  public emailTemplates: any;
  public responseBody: any;
  public changeIconToSent: number = 1;
  public eventType = 'Phase In';
  public isEmailDue = false;

  constructor(private vslService: VesselsService, private sanitizer: DomSanitizer, public dialog: MatDialog) { }

  public ngOnInit() {
    this.changeIconToSent = this.status;
    this.emailTemplates = ['Drydock', 'Idle', 'Phase In'];
    this.depEndDate = new Date(this.depEndDate);
    const curDate = new Date();
    if (curDate > this.depEndDate) {
      this.isEmailDue = true;
    }
  }

  public openTemplateDialog() {
    this.dialogRef = this.dialog.open(this.changeStatusConfirm, {
      width: '300px',
      height: '180px',
      panelClass: 'dialog-container',
      disableClose: true,
      autoFocus: false,
    });
  }

  public openRevertDialog() {
    this.dialogRef = this.dialog.open(this.revertStatusConfirm, {
      width: '280px',
      height: '140px',
      panelClass: 'dialog-container',
      disableClose: true,
      autoFocus: false,
    });
  }

  public closeEmailPopUp() {
    this.dialogRef.close();
  }

  public openOutlook() {
    // call template API here
    this.vslService.getEmailTemplate([this.pkid], this.eventType).subscribe((result: any) => {
      const subject = encodeURIComponent(result.subject);
      const body = encodeURIComponent(result.body);
      const to = result.to ?? ' ';
      const cc = result.cc ?? ' ';
      const mailToLink = `mailto:${to}?cc=${cc}&subject=${subject}&body=${body}`;

      window.open(mailToLink);

      this.changeEmailStatus(2);
    });
  }

  public changeEmailStatus(status: number) {
    const data = {
      depPKID: [this.pkid],
      depStatus: status,
    };
    this.vslService.setEmailStatus(data).subscribe((vessels: any) => {
      this.vslService.emitEmailStatusUpdate();
      this.changeIconToSent = status;
    });
  }
}
