// Angular
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { VesselsService } from '@cham-services/vessels/vessels.service';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../core/services/authentication/auth/auth.service';
import '@maersk-global/mds-components-core/mc-switch';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
@Component({
  selector: 'champs-a3-filters',
  templateUrl: './a3-filters.component.html',
  styleUrls: ['./a3-filters.component.scss'],
})
export class A3FiltersComponent implements OnInit {
  @Output() public toggleFilter: EventEmitter<any> = new EventEmitter();
  @Output() public filterColumn: EventEmitter<any> = new EventEmitter();
  @ViewChild('select') public select: MatSelect;
  // @Output() public dryDockToggled: EventEmitter<any> = new EventEmitter();
  public dryDockMappings: boolean = true;
  public showFilters: boolean = true;
  public filterData: any;
  public dryDockToggle: boolean = true;
  public allSelected = false;
  public months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public selectedFilters = {
    month: this.months[new Date().getMonth()],
    year: new Date().getFullYear(),
    monthYearFilter: null,
    vesselPlanningGroupMappings: [],
    lines: [],
    segments: [],
  };
  // public foodId: any;
  public selectedColumns: any;
  public columns = [{ key: 0, name: 'Route' }, { key: 0, name: 'Service' }, { key: 0, name: 'Vessel Name' }, { key: 0, name: 'Pos' }];
  public allColumns = [
    { key: 0, name: 'Route' },
    { key: 0, name: 'Service' },
    { key: 0, name: 'Vessel Name' },
    { key: 0, name: 'Vessel Type' },
    { key: 0, name: 'Operator' },
    { key: 0, name: 'Nominal TEU.' },
    { key: 0, name: 'VSA Nominal TEU' },
    { key: 0, name: 'Beam' },
    { key: 0, name: 'Plugs' },
    { key: 0, name: 'Next Dry Dock Date' },
    { key: 0, name: 'Start Date' },
    { key: 0, name: 'End Date' },
    { key: 0, name: 'Pos' },
    { key: 0, name: 'CII' },
    { key: 0, name: 'Fleet Risk Score' },
    { key: 0, name: 'Scrubber' },
    { key: 0, name: 'Flag' },
    { key: 0, name: 'LOA' },
    { key: 0, name: 'Deadweight' },
    { key: 0, name: 'Scantling Draft' },
    { key: 0, name: 'Keel-Mast Distance' },
    // { name: 'Total no. of reefer plugs' },
    { key: 0, name: 'Cranes' },
    { key: 0, name: 'AMP Compliance' },
    { key: 0, name: 'Panama fitted' },
    { key: 0, name: 'Segment' },
    { key: 0, name: 'Comment' },
    { key: 0, name: 'Start port' },
    { key: 0, name: 'End port' },
    { key: 0, name: 'Start region' },
    { key: 0, name: 'End region' },
  ];
  // public patientCategory: FormGroup;

  public years: number[] = Array.from({ length: 20 }, (v, k) => k + 2021);
  public listOfObjs = [];
  // selections: Array<any>;
  public filtersCtrls = {
    columnMultiCtrl: new UntypedFormControl(),
  };

  public userRole: any;

  public userId: any;

  constructor(private vslService: VesselsService, private http: HttpClient, private fb: UntypedFormBuilder, private auth: AuthService) { }

  public ngOnInit(): void {
    this.setPreSetColumns();
    // this.foodId = new FormControl(this.columns[0].name);
    this.selectedColumns = this.columns[0];
    this.vslService.a3Data.subscribe((res: any) => {
        // tslint:disable-next-line:no-console
      if (res?.distColumns) {
        this.columns = res.distColumns.map(col => ({ key: 0, name: col }));
        this.selectColumnsToDisplay();
        // tslint:disable-next-line:no-console
      }
    });
    if (this.auth.userProfile) {
      this.userRole = this.auth.userProfile.role ? this.auth.userProfile.roles[0] : 'L3';
      //updated to return UID for CDA Accounts
      this.userId = this.auth.userProfile.UID ? this.auth.userProfile.UID : 'CDA001';
    }
  }
  public setPreSetColumns() {
    this.filtersCtrls.columnMultiCtrl.setValue(['Route']);
  }
  public showFilter() {
    this.toggleFilter.emit({ show: true });
  }

  public toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  // compareObjects(o1: any, o2: any): boolean {
  //   return o1.name === o2.name;
  // }

  public hideDryDock(e) {
    this.dryDockMappings = e.srcElement.button.ariaChecked === 'true' ? false : true;
    this.applyFilters();
    // this.dryDockToggled.emit({ flag: this.dryDockMappings });
  }

  public applyFilters(obj?) {
    this.selectedFilters.monthYearFilter = this.selectedFilters.month && this.selectedFilters.year
      ? this.selectedFilters.month.slice(0, 3) + '-' + this.selectedFilters.year
      : moment().format('MMM-YYYY');
    this.vslService.getA3ServiceViewData({
      ...this.selectedFilters,
      lines: this.selectedFilters.lines.toString(),
      vesselPlanningGroupMappings: this.selectedFilters.vesselPlanningGroupMappings.toString(),
      segments: this.selectedFilters.segments.toString(),
      showDryDock: this.dryDockMappings,
      pageNum: obj ? obj.pageNum : 1,
      PageSize: obj ? obj.pageSize : 25,
      userId: this.userId
    }, true);
  }

  // public saveFilters(e) {
  //   const selectedColumnsArr = this.columns.map(col => col.name);
  //   const selectedColumns = selectedColumnsArr.join(',');
  //   this.saveFilter.emit(selectedColumns);
  // }

  public cancel() {
    this.selectedFilters = {
      month: null,
      year: null,
      monthYearFilter: null,
      vesselPlanningGroupMappings: [],
      lines: [],
      segments: [],
    };
  }

  public selectColumnsToDisplay() {
    this.filterColumn.emit(this.columns);
  }
}
