/* */
import { Component, OnInit, ViewChild, AfterViewInit,EventEmitter,Output } from '@angular/core';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { MY_FORMATS, extractDateOnly } from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import '@maersk-global/mds-components-community';
import "@maersk-global/mds-components-core/mc-icon";
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { ExcelService } from '../../..../../core/services/download-excel/excel.service';

@Component({
  selector: 'champs-service-view',
  templateUrl: './service-view.component.html',
  styleUrls: ['./service-view.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class ServiceViewComponent implements OnInit, AfterViewInit {
  @ViewChild('serviceViewTable') table: any;
  public columns = [{ key: 0, name: 'Year week' }, { key: 0, name: 'Vsl name' }, { key: 0, name: 'Operator' },
  { key: 0, name: 'LOA' },{ key: 0, name: 'Beam' },{ key: 0, name: 'Nominal TEU' }];
  public allColumns = [
    { key: 0, name: 'Year week' },
    { key: 0, name: 'Vsl name' },
    { key: 0, name: 'Vsl type' },
    { key: 0, name: 'Operator' },
    { key: 0, name: 'CII' },
    { key: 0, name: 'FleetRisk Score' },
    { key: 0, name: 'Scrubber' },
    { key: 0, name: 'Flag' },
    { key: 0, name: 'LOA' },
    { key: 0, name: 'Beam' },
    { key: 0, name: 'Deadweight' },
    { key: 0, name: 'Scantling Draft' },
    { key: 0, name: 'Keel-Mast distance' },
    { key: 0, name: 'Total no. of reefer plugs' },
    { key: 0, name: 'Cranes' },
    { key: 0, name: 'AMP Compliance' },
    { key: 0, name: 'Panama' },
    { key: 0, name: 'Segment' },
    { key: 0, name: 'Nominal TEU' },
    { key: 0, name: 'VSA Nominal TEU' }
  ];

  public vslNameSelected: boolean = false;
  public vslTypeSelected: boolean = false;
  public operatorSelected: boolean = false;
  public ciiSelected: boolean = false;
  public fleetRiskScoreSelected: boolean = false;
  public scrubberSelected: boolean = false;
  public flagSelected: boolean = false;
  public loaSelected: boolean = false;
  public beamSelected: boolean = false;

  public deadWeightSelected: boolean = false;
  public scantlingDraftSelected: boolean = false;
  public keelMastSelected: boolean = false;
  public reeferSelected: boolean = false;
  public cranesSelected: boolean = false;
  public ampSelected: boolean = false;

  public panamaSelected: boolean = false;
  public segmentSelected: boolean = false;
  public nominalTEUSelected: boolean = false;
  public nominal2MTEUSelected: boolean = false;

  rows = []
  rowsWithoutFilters = []
  modifiedData = [];
  public serviceViewDataWithoutFilters:any=[];
  public serviceViewData:any=[];
  public filterData:any=[];
  public yearWeeks:any = [];
  public showFilters: boolean = true;
  public userId: any;
  public selectedService: any = null;
  public filtersSelected: any = null;

  constructor(
    private vslService: VesselsService,
    private auth: AuthService,
    private excelService: ExcelService
  ) {
  }
  public ngAfterViewInit(): void {
  }
  /** */
  public ngOnInit() {
    //updated to return UID for CDA Accounts
    this.userId = this.auth.userProfile.UID ? this.auth.userProfile.UID : 'CDA001';
    let obj={};
   //filters API
     this.vslService.getFiltersWeeklyCapacityView(this.userId).subscribe((res)=> {
       this.filterData = res;
       if(this.filterData.distColumns!==null){
         this.columns = this.filterData.distColumns.map(col => ({ key: 0, name: col }));
         this.getSavedColumns(this.filterData.distColumns);
       } else {
         this.beamSelected = true;
         this.nominalTEUSelected = true;
         this.loaSelected = true;
         this.vslNameSelected= true;
         this.operatorSelected = true;
       }
   obj = {
     "trade":this.filterData.trade? this.getSelectedFilter(this.filterData.trade):[],
     "route": this.filterData.route? this.getSelectedFilter(this.filterData.route):[],
     "service": this.filterData.service? this.getSelectedFilter(this.filterData.service):[],
     "amp": this.filterData.amp? this.getSelectedFilter(this.filterData.amp):[],
     "cranes":this.filterData.cranes? this.getSelectedFilter(this.filterData.cranes):[],
     "segment": this.filterData.segment? this.getSelectedFilter(this.filterData.segment):[],
     "panama":this.filterData.panama? this.getSelectedFilter(this.filterData.panama):[],
     "scrubber": this.filterData.scrubber? this.getSelectedFilter(this.filterData.scrubber):[],
     "portType":this.filterData.portType ?

     [
       {
         "key": 0,
         "value": this.getPortVal(this.filterData.portType),
         "kvIsChecked":  true
       }
     ]

     : [
       {
         "key": 0,
         "value": "Phase in Port",
         "kvIsChecked": true
       }
     ],
     "userId": this.userId,
     "startdate": this.filterData.startDate ? this.filterData.startDate : null,
     "startWeekDays":this.filterData.startWeekDays ? this.filterData.startWeekDays : null,
     "startWeekYear":this.filterData.startWeekYear ? this.filterData.startWeekYear : null,
     "endDate": this.filterData.endDate ? this.filterData.endDate : null,
     "endWeekYear":this.filterData.endWeekYear ? this.filterData.endWeekYear : null,
     "endWeekDays":this.filterData.endWeekDays ? this.filterData.endWeekDays : null,
     "isSaveButtonClicked": false,
     "distColumns": this.filterData.distColumns ? this.filterData.distColumns:null
   }
   this.vslService.getWeeklyCapacityViewData(obj).subscribe((res) => {
     this.serviceViewData =  res;
     this.rows = this.serviceViewData.serviceViewDetails;
   });
  })
   }
  public getPortVal(portVal) {
    const arr1:any=[];
    portVal?.forEach((n: any) => {
      if (n.kvIsChecked === true) {
        arr1.push(n);
      }
    });
    return arr1[0]?.value;
  }
  public getSelectedFilter(val:any) {
    const arr1: any = [];

    val?.forEach((n: any) => {
      if (n.kvIsChecked === true) {
        arr1.push(n);
      }
    });
    return arr1;

  }
  public recalculateTable() {
    setTimeout(() => {
      this.table.resize.emit();
    }, 200);

  }
  public toggleFilter(event: any) {
    this.showFilters = !this.showFilters;
  }

  public toggleSelectedFilters(event: any) {
    if (event) {
      //Closing service details block on Apply or Save button click
      this.selectedService=null;
      const selectedFilters = event;
      const obj = this.saveViewForServiceView(selectedFilters);
      this.filtersSelected = obj;
      this.vslService.getWeeklyCapacityViewData(obj).subscribe((res) => {
        this.serviceViewData = res;
        this.rows = this.serviceViewData.serviceViewDetails;
      });
    }
  }

  public saveViewForServiceView(obj){
    const newObj={...obj,distColumns:this.getSelectedColumnsToSave().length==0?[]:this.getSelectedColumnsToSave()};
    return newObj;
  }

public getSelectedColumnsToSave() {
  let arr = [];
  if(this.vslNameSelected){
    arr.push('Vsl name');
  }
  if(this.vslTypeSelected){
    arr.push('Vsl type');
  }
  if(this.beamSelected){
    arr.push('Beam');
  }
  if(this.ampSelected){
    arr.push('AMP Compliance');
  }
  if(this.operatorSelected){
    arr.push('Operator');
  }
  if(this.ciiSelected){
    arr.push("CII");
  }

  if(this.fleetRiskScoreSelected){
    arr.push("FleetRisk Score");
  }
  if(this.scrubberSelected){
    arr.push("Scrubber");
  }
  if(this.flagSelected){
    arr.push("Flag");
  }
  if(this.loaSelected){
    arr.push("LOA");
  }
  if(this.deadWeightSelected){
    arr.push("Deadweight");
  }

  if(this.scantlingDraftSelected){
    arr.push("Scantling Draft");
  }
  if(this.keelMastSelected){
    arr.push("Keel-Mast distance");
  }
  if(this.reeferSelected){
    arr.push("Total no. of reefer plugs");
  }

  if(this.cranesSelected){
    arr.push("Cranes");
  }
  if(this.panamaSelected){
    arr.push("Panama");
  }
  if(this.segmentSelected){
    arr.push("Segment");
  }
  if(this.nominalTEUSelected){
    arr.push("Nominal TEU");
  }
  if(this.nominal2MTEUSelected){
    arr.push("VSA Nominal TEU");
  }
  return arr;
}
  getGroupRowHeight(group, rowHeight) {
    let style = {};

    style = {
      height: group.length * 40 + 'px',
      width: '100%'
    };

    return style;
  }

  toggleExpandGroup(group) {
    this.table.groupHeader.toggleExpandGroup(group);
  }
  openProformaPage(group){
    let data = group.value;
    const result = data.find((e)=>e.serviceCode !== 0 && e.proformaID!==0);
    window.open('/services/' + result.serviceID +'/schedule/'+ result.proformaID + '/true/deployments/true', '_blank');
  }

  setPage(e){
    this.selectedService = null;
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  public selectColumnsToDisplay(selCol) {
    this.vslNameSelected = selCol.some(col => col.name === 'Vsl name');

    this.vslTypeSelected = selCol.some(col => col.name === 'Vsl type');

    this.operatorSelected = selCol.some(col => col.name === 'Operator');

    this.ciiSelected = selCol.some(col => col.name === 'CII');

    this.fleetRiskScoreSelected = selCol.some(col => col.name === 'FleetRisk Score');

    this.scrubberSelected = selCol.some(col => col.name === 'Scrubber');

    this.flagSelected = selCol.some(col => col.name === 'Flag');

    this.loaSelected = selCol.some(col => col.name === 'LOA');

    this.beamSelected = selCol.some(col => col.name === 'Beam');

    this.deadWeightSelected = selCol.some(col => col.name === 'Deadweight');

    this.scantlingDraftSelected = selCol.some(col => col.name === 'Scantling Draft');

    this.keelMastSelected = selCol.some(col => col.name === 'Keel-Mast distance');

    this.reeferSelected = selCol.some(col => col.name === 'Total no. of reefer plugs');

    this.cranesSelected = selCol.some(col => col.name === 'Cranes');

    this.ampSelected = selCol.some(col => col.name === 'AMP Compliance');

    this.panamaSelected = selCol.some(col => col.name === 'Panama');

    this.segmentSelected = selCol.some(col => col.name === 'Segment');

    this.nominalTEUSelected = selCol.some(col => col.name === 'Nominal TEU');

    this.nominal2MTEUSelected = selCol.some(col => col.name === 'VSA Nominal TEU');

  }

  public getSavedColumns(savedcol){
    for (let i = 0; i < savedcol.length; i++) {
      switch (savedcol[i]) {
        case 'Vsl name':
          this.vslNameSelected = true;
          break;
        case 'Vsl type':
          this.vslTypeSelected = true;
          break;
        case 'Operator':
          this.operatorSelected = true;
          break;
        case 'CII':
          this.ciiSelected = true;
          break;
        case 'FleetRisk Score':
          this.fleetRiskScoreSelected = true;
          break;
        case 'Scrubber':
          this.scrubberSelected = true;
          break;
        case 'Flag':
          this.flagSelected = true;
          break;
        case 'LOA':
         this.loaSelected = true;
          break;
        case 'Beam':
          this.beamSelected = true;
          break;
        case 'Deadweight':
          this.deadWeightSelected = true;
          break;
        case 'Scantling Draft':
          this.scantlingDraftSelected = true;
          break;
        case 'Keel-Mast distance':
          this.keelMastSelected = true;
          break;
        case 'Total no. of reefer plugs':
          this.reeferSelected = true;
          break;
        case 'Cranes':
          this.cranesSelected = true;
          break;
        case 'AMP Compliance':
          this.ampSelected = true;
          break;
        case 'Panama':
          this.panamaSelected = true;
          break;
        case 'Segment':
          this.segmentSelected = true;
          break;
        case 'Nominal TEU':
          this.nominalTEUSelected = true;
          break;
        case 'VSA Nominal TEU':
          this.nominal2MTEUSelected = true;
          break;
        default:
          console.log('default');
      }
    }
  }

  public exportServiceView (val) {
    if(val){
    // with filters
    for(let i=0;i<this.rows.length;i++) {
      if(this.rows[i]?.serviceName == this.rows[i+1]?.serviceName){
          this.rows[i].newService = false;
      } else {
        this.rows[i].newService = true;
      }
    }

    const sortedRows = this.rows.sort((a, b) => {
      // Compare the Route strings
      return a.route.localeCompare(b.route);
    });
    //service specific information data (1-10)
    const seenServiceCodes = new Set();
    const mergedrows = sortedRows.reduce((acc, e) => {
      if (!seenServiceCodes.has(e.serviceCode)) {
        seenServiceCodes.add(e.serviceCode);
        acc.push({
          "": sortedRows.find(service => service.serviceCode === e.serviceCode).route + '/'  + e.serviceCode,
          "Service name": this.serviceViewData.serviceName[e.serviceCode],
          "% ML": this.serviceViewData.perML13wk[e.serviceCode] + '%',
          "% fixed": this.serviceViewData.perFixed13wk[e.serviceCode] + '%',
          'No. Cancellations (13wks)': this.serviceViewData.blankRowsCount[e.serviceCode],
          'Avg CII (13wks)': this.serviceViewData.avgCII13wk[e.serviceCode],
          'Avg FleetRisk (13wks)': this.serviceViewData.avgFleetRisk[e.serviceCode] + '%',
          'YT Service Consumption': '-',
          '% Scrubber': this.serviceViewData.perScrubber[e.serviceCode] + '%',
          'Port Code': this.serviceViewData.portCodes[e.serviceCode]
        });
      }
      return acc;
    }, []);

    //data for each services
    const modified_rows = sortedRows.map((e) => {
      const row = {}
      row["Year week"] = e.yearWeek
      if (this.vslNameSelected) row["Vsl name"] = e.vesselName;      
      if (this.vslTypeSelected) row["Vsl type"] = e.vesselType;
      if (this.operatorSelected) row["Operator"] = e.operator;
      if (this.ciiSelected) row["CII"] = e.cii;
      if (this.fleetRiskScoreSelected) row["FleetRisk Score"] = e.fleetRiskScore;
      if (this.scrubberSelected) row["Scrubber"] = e.scrubber;
      if (this.flagSelected) row["Flag"] = e.flag;
      if (this.loaSelected) row["LOA"] = e.loa;
      if (this.beamSelected) row["Beam"] = e.beam;
      if (this.deadWeightSelected) row["Deadweight"] = e.deadWeight;
      if (this.scantlingDraftSelected) row["Scantling Draft"] = e.scantingDraft;
      if (this.keelMastSelected) row["Keel-Mast distance"] = e.keelMaxDistance;
      if (this.reeferSelected) row["Total no. of reefer plugs"] = e.totaPlugs;
      if (this.cranesSelected) row["Cranes"] = e.cranes;
      if (this.ampSelected) row["AMP Compliance"] = e.ampCompliance;
      if (this.panamaSelected) row["Panama"] = e.panama;
      if (this.segmentSelected) row["Segment"] = e.segment;
      if (this.nominalTEUSelected) row["Nominal TEU"] = e.nominalTEU;
      if (this.nominal2MTEUSelected) row["VSA Nominal TEU"] = e.nominalTEU2M;
      row["Service name"] = e.serviceName
      row["Trade"] = e.trade
      row[" "] = e.newService
      return row
    });
    // set headers of excel columns
    const header = Object.keys(modified_rows[0])

    const width = {
      'Year week': 10, 
      'Vsl name': 20, 
      'Vsl type': 10, 
      'Operator': 10,
      'CII': 4,
      'FleetRisk Score': 15,
      'Scrubber': 10,
      'Flag': 15,
      'LOA': 8,
      'Beam': 8,
      'Deadweight': 11,
      'Scantling Draft': 13,
      'Keel-Mast distance': 16,
      'Total no. of reefer plugs': 19,
      'Cranes': 7,
      'AMP Compliance': 14,
      'Panama': 14,
      'Segment': 8,
      'Nominal TEU': 11,
      'VSA Nominal TEU': 14,
      'Service name': 0.1,
      'Trade': 0.1,
      ' ': 0.1
    };

    // name of excel, data, column names, width
    this.excelService.generateServiceViewExcel('Service_View_With_Filters', mergedrows, modified_rows, header, width, this.serviceViewData, sortedRows);
  } else {
    // without filters
    const obj = {
      "trade":[],
      "route": [],
      "service":[],
      "amp":[],
      "cranes":[],
      "segment": [],
      "panama":[],
      "scrubber": [],
      "portType": [
        {
          "key": 0,
          "value":  this.getPortValExport(),
          "kvIsChecked": true
        }
      ],
      "userId": this.userId,
      "startdate":  null,
      "startWeekDays": null,
      "startWeekYear": null,
      "endDate":  null,
      "endWeekYear": null,
      "endWeekDays": null,
      "isSaveButtonClicked": false,
      "distColumns": []
    }
    this.vslService.getWeeklyCapacityViewData(obj).subscribe((res) => {
      this.serviceViewDataWithoutFilters = res;
      this.rowsWithoutFilters = this.serviceViewDataWithoutFilters.serviceViewDetails;
      for(let i=0;i<this.rowsWithoutFilters.length;i++) {
        if(this.rowsWithoutFilters[i]?.serviceName == this.rowsWithoutFilters[i+1]?.serviceName){
            this.rowsWithoutFilters[i].newService = false;
        } else {
          this.rowsWithoutFilters[i].newService = true;
        }
      }

      const sortedRowsWithoutFilter = this.rowsWithoutFilters.sort((a, b) => {
        // Compare the Route strings
        return a.route.localeCompare(b.route);
      });

      const seenServiceCodes = new Set();
      const mergedrows = sortedRowsWithoutFilter.reduce((acc, e) => {
        if (!seenServiceCodes.has(e.serviceCode)) {
          seenServiceCodes.add(e.serviceCode);
          acc.push({
            "": sortedRowsWithoutFilter.find(service => service.serviceCode === e.serviceCode).route + '/'  + e.serviceCode,
            "Service name": this.serviceViewData.serviceName[e.serviceCode] + sortedRowsWithoutFilter.find(service => service.serviceCode === e.serviceCode).segment,
            "% ML": this.serviceViewData.perML13wk[e.serviceCode] + '%',
            "% fixed": this.serviceViewData.perFixed13wk[e.serviceCode]+ '%',
            'No. Cancellations (13wks)': this.serviceViewData.blankRowsCount[e.serviceCode],
            'Avg CII (13wks)': this.serviceViewData.avgCII13wk[e.serviceCode],
            'Avg FleetRisk (13wks)': this.serviceViewData.avgFleetRisk[e.serviceCode]+ '%',
            'YT Service Consumption': '-',
            '% Scrubber': this.serviceViewData.perScrubber[e.serviceCode]+ '%',
            'Port Code': this.serviceViewData.portCodes[e.serviceCode]
          });
        }
        return acc;
      }, []);

      const modified_rows = sortedRowsWithoutFilter.map((e) => {
        return {
          "Year week":e.yearWeek,
          "Vsl name": e.vesselName,
          "Vsl type": e.vesselType,
          'Operator':e.operator,
          'CII':e.cii,
          'FleetRisk Score':e.fleetRiskScore,
          'Scrubber':e.scrubber,
          'Flag':e.flag,
          'LOA':e.loa,
          'Beam':e.beam,
          'Deadweight':e.deadWeight,
          'Scantling Draft':e.scantingDraft,
          'Keel-Mast distance':e.keelMaxDistance,
          'Total no. of reefer plugs':e.totaPlugs,
          'Cranes':e.cranes,
          'AMP Compliance':e.ampCompliance,
          'Panama':e.panama,
          'Segment':e.segment,
          'Nominal TEU':e.nominalTEU,
          'VSA Nominal TEU':e.nominalTEU2M,
          "Service name":e.serviceName,
          'Trade': e.trade,
          ' ':e.newService
        };
      });

      // set headers of excel columns
      const header = [
        "Year week",
        'Vsl name',
        'Vsl type',
        'Operator',
        'CII',
        'FleetRisk Score',
        'Scrubber',
        'Flag',
        'LOA',
        'Beam',
        'Deadweight',
        'Scantling Draft',
        'Keel-Mast distance',
        'Total no. of reefer plugs',
        'Cranes',
        'AMP Compliance',
        'Panama',
        'Segment',
        'Nominal TEU',
        'VSA Nominal TEU',
        "Service name",
        'Trade',
        ' '
      ];

      const width = {
        'Year week': 10, 
        'Vsl name': 20, 
        'Vsl type': 10, 
        'Operator': 10,
        'CII': 4,
        'FleetRisk Score': 15,
        'Scrubber': 10,
        'Flag': 15,
        'LOA': 8,
        'Beam': 8,
        'Deadweight': 11,
        'Scantling Draft': 13,
        'Keel-Mast distance': 16,
        'Total no. of reefer plugs': 19,
        'Cranes': 7,
        'AMP Compliance': 14,
        'Panama': 14,
        'Segment': 8,
        'Nominal TEU': 11,
        'VSA Nominal TEU': 14,
        'Service name': 0,
        'Trade': 0,
        ' ': 0.1
      };
      // name of excel, data, column names, width
      this.excelService.generateServiceViewExcel('Service_View_Without_Filters',mergedrows, modified_rows, header, width, this.serviceViewData, sortedRowsWithoutFilter);
    });
  }
  }

  public getCellClass({ row, column, value }): any {
    return {
      'blanking-color': row['vesselName'] == null ? true : false,
      'blanking-dummy-vsl-color': row['vesselType'] == 'Dummy' ? true : false,
    };
  }

  public toggleServiceDetail(event) {
    let selectedRowData = event;
    if (event.type === 'close') {
      this.selectedService = null;
    } else if (this.selectedService?.vesselName === event.vesselName) {
      this.selectedService = null;
    } else {
      this.selectedService = event;
    }
  }

  public showProformaPageLink(val){
    let data = val.value;
    const result = data.some((e)=>e.serviceCode !== 0 && e.proformaID!==0);
    return result;
  }

  public getPortValExport() {
    let portVal: any[];
    if (this.filtersSelected == null || this.filtersSelected.portType == null) {
      portVal = this.filterData.portType.filter((n) => n.kvIsChecked);
    } else {
      portVal = this.filtersSelected.portType;
    }
    return portVal[0].value.toString();
  }
}
