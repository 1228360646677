// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AdalService } from 'adal-angular4';

// Modules
import { ComponentsModule } from './components/components.module';

// Components
import { VesselListComponent } from './pages/vessel-list/vessel-list.component';
import { VesselDetailComponent } from './pages/vessel-detail/vessel-detail.component';
import { VesselNewComponent } from './pages/vessel-new/vessel-new.component';
import { VesselImportComponent } from './pages/vessel-import/vessel-import.component';
import { HomePageComponent } from 'src/app/pages/home-page/home-page.component';
import { StandaloneReportAppComponent } from 'src/app/pages/standalone-report-app/standalone-report-app.component';
import { StandaloneReportDashboardsComponent } from 'src/app/pages/standalone-report-app/dashboards/standalone-report-dashboards';
import { DashboardReports } from 'src/app/pages/standalone-report-app/dashboard-reports/dashboard-reports';
import { VesselDetailOverviewComponent } from './pages/vessel-detail/overview/overview.component';
import { VesselDetailDetailsComponent } from './pages/vessel-detail/details/details.component';
import { VesselDetailEvaluationComponent } from './pages/vessel-detail/evaluation/evaluation.component';
import { UnauthorisedComponent } from './components/unauthorised/unauthorised.component';
import { VeselNewDummyComponent } from './pages/vesel-new-dummy/vesel-new-dummy.component';
import { FeatureToggleComponent } from './pages/feature-toggle/feature-toggle.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { A3ViewComponent } from './pages/a3-view/a3-view.component';
import { ServiceViewComponent } from './pages/service-view/service-view.component';
import { HealthComponent } from './pages/health/health.component';
// Services
import { AuthGuard } from './core/services/authentication/auth-guard/auth-guard.service';
import { CanDeactivateGuard } from './modules/can-deactivate/services/can-deactivate-guard/can-deactivate.guard';
import { VesselDetailsResolver } from './pages/vessel-detail/vessel-detail.resolver';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { environment } from '@cham-environments/environment';
import { PowerbiReportComponent } from './pages/standalone-report-app/powerbi-report/powerbi-report/powerbi-report.component';
import { PowerbiReportListComponent } from './pages/standalone-report-app/powerbi-report/powerbi-report-list/powerbi-report-list.component';
import { SelfServiceComponent } from './pages/self-service/self-service.component';

/**
 * Routes variable
 */
const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: '',
    // Remove MsalGuard to allow BYPASS_AUTHENTICATION

    canActivateChild: environment.byPassAuthentication === 'true' ? [AuthGuard] : [AuthGuard, MsalGuard],
    children: [
      {
        path: 'unauthorised',
        component: UnauthorisedComponent,
      },
      {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full',
      },
      {
        path: 'reports',
        component: StandaloneReportAppComponent,
        pathMatch: 'full',
      },
      {
        path: 'reports/application-reports',
        component: PowerbiReportListComponent
      },
      {
        path: 'reports/public-reports',
        component: PowerbiReportListComponent
      },
      {
        path: 'reports/:id',
        component: PowerbiReportComponent,
      },
      {
        path: 'health',
        component: HealthComponent,
        pathMatch: 'full',
      },
      {
        path: 'standalone-dashboard',
        component: StandaloneReportDashboardsComponent,
        pathMatch: 'full',
      },
      {
        path: 'dashboard-reports',
        component: DashboardReports,
      },
      {
        path: 'deployment',
        component: A3ViewComponent,
        pathMatch: 'full',
        data: {
          title: 'A3View',
          module: 'A3View',
        },
      },
      {
        path: 'service-view',
        component: ServiceViewComponent,
        pathMatch: 'full',
        data: {
          title: 'ServiceView',
          module: 'ServiceView',
        },
      },
      {
        path: 'feature-toggle',
        component: FeatureToggleComponent,
        pathMatch: 'full',
      },
      {
        path: 'vessels',
        component: VesselListComponent,
        pathMatch: 'full',
        data: {
          title: 'Vessels',
          module: 'Vessels',
        },
      },
      {
        path: 'vessels/new',
        component: VesselNewComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'Vessels',
          module: 'Vessels',
        },
      },
      {
        path: 'vessels/createdummy',
        component: VeselNewDummyComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'Vessels',
          module: 'Vessels',
        },
      },
      {
        path: 'vessels/import',
        component: VesselImportComponent,
        data: {
          title: 'Vessels',
          module: 'Vessels',
        },
      },
      {
        path: 'vessels/import/compare',
        component: VesselNewComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'Vessels',
          module: 'Vessels',
        },
      },
      {
        path: 'vessels/dummy/overview/:id',
        component: VeselNewDummyComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'Dummy Overview',
          module: 'Vessels',
          fromVessel: true,
        },
      },
      // Extend To Dummy routing
      {
        path: 'vessels/extenddummy/overview/:id',
        component: VeselNewDummyComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'Extend Dummy',
          module: 'Vessels',
          fromVesselDetail: true,
        },
      },
      {
        path: 'vessels/:id',
        component: VesselDetailComponent,
        resolve: {
          details: VesselDetailsResolver,
        },
        data: {
          title: 'Vessels',
          module: 'Vessels',
        },
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            component: VesselDetailOverviewComponent,
            data: {
              title: 'Vessels',
              module: 'Vessels',
            },
          },
          {
            path: 'details',
            component: VesselDetailDetailsComponent,
            data: {
              title: 'Vessels',
              module: 'Vessels',
            },
          },
          {
            path: 'evaluation',
            component: VesselDetailEvaluationComponent,
            data: {
              title: 'Vessels',
              module: 'Vessels',
            },
          },
        ],
      },
      {
        path: 'self-service',
        component: SelfServiceComponent
      },
      {
        path: 'fixtures',
        loadChildren: () => import('./modules/fixtures/fixtures.module').then(m => m.FixturesModule),
        data: {
          title: 'Fixtures',
          module: 'Fixtures',
        },
      },
      {
        path: 'owners',
        loadChildren: () => import('./modules/owners/owners.module').then(m => m.OwnersModule),
        data: {
          title: 'Owners',
          module: 'Owners',
        },
      },
      {
        path: 'scenarios',
        loadChildren: () => import('./modules/scenarios/scenarios.module').then(m => m.ScenariosModule),
        data: {
          title: 'Scenarios',
          module: 'Scenarios',
        },
      },
      {
        path: 'dictionary',
        loadChildren: () => import('./modules/dictionary/dictionary.module').then(m => m.DictionaryModule),
        data: {
          title: 'Dictionary',
          module: 'Dictionary',
        },
      },
      {
        path: 'market-rates',
        loadChildren: () => import('./modules/market-rates/market-rates.module').then(m => m.MarketRateModule),
        data: {
          title: 'Market Rates',
          module: 'Market Rates',
        },
      },
      {
        path: 'charter-request',
        loadChildren: () => import('./modules/charter-request/charter-request.module').then(m => m.CharterRequestModule),
        data: {
          title: 'Charter Requests',
          module: 'Charter Requests',
        },
      },
      {
        path: 'services',
        loadChildren: () => import('./modules/service-repository/service-repository.module').then(m => m.ServiceRepositoryModule),
        data: {
          title: 'Services',
          module: 'Services',
        },
      },
      {
        path: 'charter-candidate',
        loadChildren: () => import('./modules/candidate/candidate.module').then(m => m.CandidateModule),
        data: {
          title: 'Charter Candidates',
          module: 'Charter Candidates',
        },
      },
      {
        path: 'average-bunker',
        loadChildren: () => import('./modules/avg-bunker/avg-bunker.module').then(m => m.AvgBunkerModule),
        data: {
          title: 'Average Bunker',
          module: 'Average Bunker',
        },
      },
      {
        path: 'administrator',
        loadChildren: () => import('./modules/administrator/administrator.module').then(m => m.AdministratorModule),
        data: {
          title: 'Administrator',
          module: 'Administrator',
        },
      },
      {
        path: 'postfixture-dashboard',
        loadChildren: () => import('./modules/post-fixture-dashboard/post-fixture-dashboard.module').then(m => m.PostFixtureDashboardModule),
        data: {
          title: 'Post Fixture Dashboard',
        },
      },
      {
        path: 'finance-dashboard',
        loadChildren: () => import('./modules/finance-dashboard/finance-dashboard.module').then(m => m.FinanceDashboardModule),
        data: {
          title: 'Finance Dashboard',
        },
      },
      {
        path: 'chartering-dashboard',
        loadChildren: () => import('./modules/chartering-dashboard/chartering-dashboard.module').then(m => m.CharteringDashboardModule),
        data: {
          title: 'Chartering Dashboard',
        },
      },
      {
        path: 'deployment-dashboard',
        loadChildren: () => import('./modules/deployment-dashboard/deployment-dashboard.module').then(m => m.DeploymentDashboardModule),
        data: {
          title: 'Deployment Dashboard',
        },
      },
      {
        path: ':moduleId/change-log/:id',
        loadChildren: () => import('./modules/change-log/change-log.module').then(m => m.ChangeLogModule),
        data: {
          title: 'Change Log',
          module: 'Change Log',
        },
      },
      {
        path: ':moduleId/changelog/:id',
        loadChildren: () => import('./modules/changelog/changelog.module').then(m => m.ChangeLogModule),
        data: {
          title: 'Change Log',
          module: 'Change Log',
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        initialNavigation: 'enabledBlocking',
      }
      // { enableTracing: true } // <-- this should be removed - Its for debugging purposes only
    ),
    ComponentsModule,
  ],
  exports: [RouterModule],
  providers: [MsalService],
})
export class AppRoutingModule { }
