import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as VesselCreateActions from 'src/app/state/vessels/actions/vessel-create.actions';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import { PaintsService } from 'src/app/core/services/paints/paints.service';
import { CanDeactivateDialogService } from 'src/app/modules/can-deactivate/services/can-deactivate-dialog/can-deactivate-dialog.service';
import { CanDeactivateDialogToggleService } from 'src/app/modules/can-deactivate/services/can-deactivate-dialog-toggle/can-deactivate-dialog-toggle.service';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { FeatureToggleService } from 'src/app/core/services/feature-toggle/feature-toggle.service';
import * as _moment from 'moment';
import { IVesselAdditionalInfoCreate, getIMONumber } from 'src/app/state/vessels/reducers/vessel-create.reducer';
import { AuthService } from '../../core/services/authentication/auth/auth.service';
import { scrollToError } from '../../utils/scroll-utils';

const moment = _moment;

@Component({
  selector: 'champs-vessel-new-additional-information',
  templateUrl: './vessel-new-additional-information.component.html',
  styleUrls: ['./vessel-new-additional-information.component.scss'],
})
export class VesselNewAdditionalInformationComponent implements OnInit, OnDestroy {
  @ViewChildren('matError', { read: ElementRef }) public matErrorRefs: QueryList<ElementRef>;
  /** Subject that emits when the component has been destroyed. */
  public _onDestroy = new Subject<void>();
  public _showFltrValue;
  @Output() public duplicateMessage: EventEmitter<any> = new EventEmitter();
  @Output() public rkstMessage: EventEmitter<any> = new EventEmitter();
  @Output() public changeStep: EventEmitter<any> = new EventEmitter();
  @Output() public update: EventEmitter<any> = new EventEmitter();

  @Input() public values: IVesselAdditionalInfoCreate;
  public submitDisable: boolean = false;
  public showRangeError: boolean = false;
  public vesselTeuName: any;
  public vesselTeuName$: Observable<any>;
  @Input('submitDisabled')
  set submitEnabled(value: boolean) {
    this.submitDisable = value;
  }

  @Input()
  public submitSubject: Subject<any>;

  public originalValues: any = null;
  @Input('uploadValues')
  set uploadValues(value: any) {
    this.originalValues = value ? value : null;
  }

  public showRequiredOnly: boolean = false;
  @Input('requiredFltrValue')
  set requiredFltrValue(value: string) {
    this.showRequiredOnly = value === 'required' ? true : false;
  }

  public vesselQsImport: boolean = false;
  public vesselNewPage: boolean = false;
  public navigate: boolean = true;
  public isVsAddInfoUpdateAuth: boolean;

  @Input('route')
  set route(value: string) {
    if (value.includes('/vessels/new')) {
      this.vesselNewPage = true;
      this.vslAdditionalInfoViewMode = false;
    } else if (value.includes('/vessels/import')) {
      this.vesselQsImport = true;
      this.vslAdditionalInfoViewMode = false;
    }
  }

  public showFltrType: string = 'all';
  @Input('showFltrValue')
  set showFltrValue(value: Subject<string>) {
    this._showFltrValue = value;
    value.pipe(map((val) => val || 'all')).subscribe((val) => (this.showFltrType = val));
  }
  get showFltrValue() {
    return this._showFltrValue;
  }

  @Input()
  public cancelSubject: Subject<any>;
  public generalSectionFrmGrp: UntypedFormGroup;
  public rkstFormGrp: UntypedFormGroup;
  public ampCompliants$: Observable<any>;
  public armedGuards$: Observable<any>;
  public vesselTeuTypes$: Observable<any>;
  public vesselDesigns$: Observable<any>;
  public scrubbers$: Observable<any>;
  public warRiskInsurances$: Observable<any>;
  public vslAdditionalInfoViewMode: boolean = true;
  public planningGroup: Observable<any>;
  public carbonIntensityIndicator: Observable<any>;
  public ftEnabled: boolean;

  public showFormErrors: boolean = false;
  public hideSaveButtons: boolean = false;
  @ViewChild('errorConfirmation', { static: false }) public errorConfirmation: any;
  constructor(
    public vesselsService: VesselsService,
    public formBuilder: UntypedFormBuilder,
    public store: Store<any>,
    public vesselDropdowns: VesselDropdownsService,
    public paintsService: PaintsService,
    public dialog: MatDialog,
    public router: Router,
    public dialogService: CanDeactivateDialogService,
    public dialogToggleService: CanDeactivateDialogToggleService,
    public auth: AuthService,
    private ft: FeatureToggleService
  ) {
    this.ampCompliants$ = this.vesselDropdowns.getAmpCompliants();
    this.armedGuards$ = this.vesselDropdowns.getArmedGuards();
    this.vesselTeuTypes$ = this.vesselDropdowns.getVesselTypes();
    this.vesselDesigns$ = this.vesselDropdowns.getVesselDesigns();
    this.scrubbers$ = this.vesselDropdowns.getScrubbers();
    this.warRiskInsurances$ = this.vesselDropdowns.getWarRiskInsurances();
    /* CII */
    this.planningGroup = this.vesselDropdowns.getPlanningGroup();
    this.carbonIntensityIndicator = this.vesselDropdowns.getCII();
    this.ftEnabled = this.ft.isFtEnabled('CII_NEW_FIELDS');
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.isVsAddInfoUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.createGeneralInfoForm();
    this.getVesselSize();

    if (this.submitSubject) {
      this.submitSubject.subscribe(() => {
        this.createVessel();
      });
    }
    if (this.cancelSubject) {
      this.cancelSubject.subscribe(() => {
        this.cancelCreateVessel();
      });
    }
  }
  /* CII */
  public getvslCIIPlanningDrpdwns(val) {
    this.generalSectionFrmGrp
      .get('vslVesselPlanningGroupMappingID')
      .setValue(val.vslVesselPlanningGroupMapping.vpgmPkid);
    this.generalSectionFrmGrp.get('vslVesselCiiMappingID').setValue(val.vslVesselCiiMapping.vcmPkid);
  }

  public vslAdditionalInfoCancel() {
    this.vslAdditionalInfoViewMode = true;
    this.createGeneralInfoForm();
  }

  public vslAdditionalInfoSave() {
    if (!this.generalSectionFrmGrp.valid) {
      this.showFormErrors = true;
      let requiredError = false;
      Object.keys(this.generalSectionFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.generalSectionFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }

    this.showFormErrors = false;

    this.update.emit({
      type: 'UpdateAdditional',
      value: { ...this.generalSectionFrmGrp.value, vslVesselTeu: this.vesselDropdowns.vesselTeuID },

      isCreate: true,
    });

    this.vslAdditionalInfoViewMode = true;
  }

  public createVessel() {
    this.duplicateMessage.emit({
      value: null,
    });
    if (!this.generalSectionFrmGrp.valid) {
      this.showFormErrors = true;
      let requiredError = false;
      Object.keys(this.generalSectionFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.generalSectionFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    const valueObj = {
      ...this.generalSectionFrmGrp.value,
      vslVesselTeu: this.vesselDropdowns.vesselTeuID,
    };
    this.store.dispatch(new VesselCreateActions.PutAdditionalInfo(valueObj));
    this.vesselsService
      .createVessel()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.store
            .select(getIMONumber)
            .pipe(take(1))
            .subscribe((imo) => {
              this.vesselsService.getVMD(res.vessel_id).subscribe((detailObj: any) => {
                this.store.dispatch(new VesselCreateActions.ResetCreateVesselObj());
                this.router.navigate(['/vessels/' + res['vessel_id']], {
                  replaceUrl: true,
                });
              });
            });
        },
        (err) => {
          window.scroll(0, 0);
          if (err.error.error === 'Vessel Code already exists with the given details') {
            this.duplicateMessage.emit({
              value: err.error.error,
            });
          }else{
            this.duplicateMessage.emit({
              value: JSON.stringify(err),
            });
          }
        }
      );
  }

  /**
   * onDestroy life-cycle hook
   */
  public ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  /** */
  public nextStep(): void {
    if (!this.generalSectionFrmGrp.valid) {
      this.showFormErrors = true;
      if (this.vesselQsImport) {
        this.showFltrValue.next('all');
      }
      let requiredError = false;
      Object.keys(this.generalSectionFrmGrp.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.generalSectionFrmGrp.get(key).errors;
        if (controlErrors != null) {
          for (const error of Object.keys(controlErrors)) {
            if (error === 'required') {
              requiredError = true;
              break;
            }
          }
        }
      });
      if (requiredError) {
        scrollToError(this.matErrorRefs);
        return;
      }
      scrollToError(this.matErrorRefs);
      return;
    }
    const valueObj = this.createPostObj();
    this.store.dispatch(new VesselCreateActions.PutVesselSpecification(valueObj));
    this.changeStep.emit();
  }

  public cancelCreateVessel() {
    this.dialogService.openDialog('CANCEL', `Are you sure you want to leave the page?`, {
      yes: 'YES',
      no: 'NO',
    });

    this.dialogToggleService.canDeactivateToggle.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.store.dispatch(new VesselCreateActions.ResetCreateVesselObj());
        this.router.navigate(['/vessels'], {
          replaceUrl: true,
        });
      }
    });
  }

  public createPostObj() {
    return {
      ...this.generalSectionFrmGrp.value,
      vslVesselTeu: this.vesselDropdowns.vesselTeuID,
    };
  }
  public getVesselSize() {
    this.vesselTeuTypes$.subscribe((data) => {
      this.vesselDropdowns.vesselTeuName = '';
      this.vesselDropdowns.vesselTeuID = '';
      const x = { ...this.values };
      this.vesselDropdowns.vesselTeuName =
        data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuName !==
        undefined
          ? data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuName
          : null;

      this.vesselDropdowns.vesselTeuID =
        data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID !==
        undefined
          ? data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID
          : null;
    });
  }
  public createGeneralInfoForm() {
    this.generalSectionFrmGrp = this.formBuilder.group({
      vslVesselTeu: this.values.vslVesselTeu ? [this.values.vslVesselTeu] : [],

      vslDesignID: this.values.vslDesignID ? [this.values.vslDesignID] : [],

      vslScrubberID: this.values.vslScrubberID ? [this.values.vslScrubberID] : [],
      vslNoOfPigtail: this.formBuilder.control(this.values.vslNoOfPigtail ? this.values.vslNoOfPigtail : null, [
        Validators.min(0),
        Validators.max(500),
        Validators.pattern('^[0-9]*$'),
      ]),
      vslisRCM: this.values.vslisRCM,
      vslMaxSpeedScantling: this.formBuilder.control(
        this.values.vslMaxSpeedScantling ? this.values.vslMaxSpeedScantling : null,
        [Validators.min(0)]
      ),

      vslWarRiskInsuranceID: this.values.vslWarRiskInsuranceID ? [this.values.vslWarRiskInsuranceID] : [],
      vslArmedGuardID: this.values.vslArmedGuardID ? [this.values.vslArmedGuardID] : [],
      vslAMPCompliantID: this.values.vslAMPCompliantID ? [this.values.vslAMPCompliantID] : [],
      /* CII */
      vslVesselPlanningGroupMappingID: this.values.vslVesselPlanningGroupMappingID
        ? [this.values.vslVesselPlanningGroupMappingID]
        : [],
      vslVesselCiiMappingID: this.values.vslVesselCiiMappingID ? [this.values.vslVesselCiiMappingID] : [],
    });
  }
}
