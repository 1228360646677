import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@cham-environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelfService {
  constructor(private http: HttpClient ) {}

  public getJobs(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/Jobs/GetAllJobs');
  }

  public updateCronExpression(jobId: number, manualCronExpression: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/Jobs/UpdateCronExpression?JobId=' + jobId + '&manualCronExpression=' + manualCronExpression, null, { responseType: 'text' });
  }
}
