// Vessel Detail - Overview Tab

import {
  Component,
  OnInit,
  AfterViewChecked,
  ViewChildren,
  ElementRef,
  QueryList,
  ViewChild,
  OnDestroy,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { VesselsService } from 'src/app/core/services/vessels/vessels.service';
import { isValidDate } from 'src/app/utils/valid-date';
import * as _moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MY_FORMATS,
  dateTimeCompile,
  time,
  customMoment,
} from 'src/app/components/custom-moment-datepicker/custom-moment-datepicker.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../core/services/authentication/auth/auth.service';
import { ColorConfigService } from 'src/app/core/services/color-config/color-config.service';
import { PortService } from '../../../core/services/port/port.service';
import { VesselDropdownsService } from 'src/app/core/services/vessel-dropdowns/vessel-dropdowns.service';
import { FeatureToggleService } from 'src/app/core/services/feature-toggle/feature-toggle.service';
import { NgxSpinnerService } from 'ngx-spinner';
const moment = customMoment;
export enum EnumConfigureMappingOfFields {
  FIELD0 = 'Planning Group',
  FIELD1 = 'Segment',
  FIELD2 = 'Nominal TEU',
  FIELD3 = 'VSA Nominal TEU',
  FIELD4 = 'Gross Tonnage',
  FIELD5 = 'LOA',
  FIELD6 = 'Beam',
  FIELD7 = 'Scanting draft',
  FIELD8 = 'Max height from Keel',
  FIELD9 = 'Max. Speed (kn)',
  FIELD10 = 'Total plugs ',
  FIELD11 = 'In holds',
  FIELD12 = 'RCM installed',
  FIELD13 = 'AMP Compliant',
  FIELD14 = 'Panama fitted',
  FIELD15 = 'ITF compliant',
  FIELD16 = 'Scrubber',
  FIELD17 = 'Number of cranes',
  FIELD18 = 'Last Dry-dock Date',
  FIELD19 = 'Next Dry-dock Date',
  FIELD20 = 'HFO',
  FIELD21 = 'AUX',
  FIELD22 = 'ULSFO',
  FIELD23 = '0.5% FO',
  FIELD24 = 'CII',
}

@Component({
  selector: 'champs-vessel-detail-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
})
export class VesselDetailOverviewComponent implements OnInit, OnDestroy, AfterViewChecked {
  private id;
  private dialogRef: MatDialogRef<any>;
  public ConfigureMappingOfFieldsHeading: any = EnumConfigureMappingOfFields;
  public changeLog: any = 'Changelog';
  public arrValue = {};
  public blockNum: any = [];
  public countIdCheck = true;
  @ViewChildren('contactItem', { read: ElementRef }) public contactItems: QueryList<ElementRef>;
  @ViewChildren('ownerItem', { read: ElementRef }) public ownerItems: QueryList<ElementRef>;
  @ViewChild('editBlackout', { static: false }) public editBlackout: any;
  @ViewChild('confirmationDialog', { static: false }) public deleteDeploymentDialogue: any;
  @ViewChild('warningDialog', { static: false }) public warningMessagetDialogue: any;
  public vesselTeuName: any;
  public vesselTeuName$: Observable<any>;
  @Input()
  public vesselTeuTypes$: Observable<any>;
  public showMoreContactsVisible: boolean = false;
  public showMoreContacts: boolean = false;
  public showMoreOwners: boolean = false;
  public showMoreOwnersVisible: boolean = false;
  public showMoreComercialOwner: boolean = false;
  public showMoreVesselOwner: boolean = true;
  public showMoreVesselContact: boolean = true;
  public showMoreTechOwner: boolean = false;
  public showMoreCrewOwner: boolean = false;
  public showMoreManagingOwner: boolean = false;
  public showMoreBrokerOwner: boolean = false;
  public showMoreHeadOwner: boolean = false;

  public vesselCurrentFixture: any;
  public vesselUpcomingFixture: any;
  public vesselPreviousFixtures: any[];
  public vesselITCFixtures: any[];

  public vesselCurrentDeployment: any;
  public vesselUpcomingDeployment: any;
  public vesselPreviousDeployments: any[];
  public vesselDeploymentDropdowns: any;

  public isNotFullView$: Observable<boolean>;
  public vesselDetail: any;
  public blackoutViolationMsg: any;

  public isExtendDummyAuth: boolean;
  public vesselDeploymentFrmGrp: UntypedFormGroup;
  public vesselDeploymentSpclServices: any = [];
  public vesselDeploymentServices: any = [];
  public vesselDepSpclServiceTypes: any = [];
  public vesselDepUnemployedTypes: any = [];
  public vesselDepLayupTypes: any = [];
  public vesselDeploymentPorts: any = [];
  public vesselDeploymentReasons: any = [];
  public vesselDeploymentStartPort: any;
  public vesselDeploymentEndPort: any;
  public roundTrip: any;
  public trade: any;
  public hideTrade: boolean = false;
  public hideRoundTrip: boolean = false;
  public showLocationHidePort: boolean = false;
  public showvesselDepSpclServiceTypes: boolean = false;
  public showvesselDepUnemployedTypes: boolean = false;
  public dontShowServiceType: boolean = false;
  public showvesselDepLayupTypes: boolean = false;
  public time = time;
  public typeNew: boolean = false;
  public createVesselDeployment: boolean = false;
  public showDetailsDiv: boolean = false;
  public showSpecialServicesFlag: boolean = false;
  public showServicePosition: boolean = false;
  public servicePositions: any = [];
  public showError: boolean = false;
  public endDateNotPresent: boolean = false;
  public locationErrorMsg: any;
  /** error keys */
  public errorKeys: string[] = [];
  /** error values */
  public errorValues: any[] = [];
  public incorrectDeploymentDetails: any;
  public showIncorrectDetailError: boolean = false;
  public previousDeploymentEndDate: any;
  public previousDeploymentId: any;
  public fixturePortsForRange: any[] = [];

  public showBeginTimeError: boolean = false;
  public showBeginDateError: boolean = false;
  public showEndTimeError: boolean = false;
  public showEndDateError: boolean = false;
  public showDatesInvalid: boolean = false;
  public showRangeInvalid: boolean = false;
  public isVsOverwUpdateAuth: boolean;
  public loading: boolean;
  public showScheduleDateError: boolean;
  public scheduleDateErrorMsg: any;
  public previousDeploymentID: any;
  public previousDeploymentDetails: any;
  public previousDeploymentIdPosition: any;
  public showPreviousFixtures = false;
  /** for color configuration */
  public red = '#d62d23';
  public orange = '#ff9b1e';
  public green = '#2dba5e';
  public black = '#000000';
  public transparentColor = '#dadada';
  public scoreColorConfig: any;
  public charterRequestRatesColorConfig: any;
  public vsaFlagUp: any;
  public vsaFlagCur: string;
  public depPositionExists: boolean = true;
  public extraLoaderPosition: any;
  public extraPosEntered: boolean = false;
  public validExtraPos: boolean = false;
  public depPositionValid: boolean = true;
  public isVesslDeplyServiceDataPositionEnterd: boolean;
  public allPortsByService: any;
  public noFixtureMsg: boolean = false;
  // Extend To Dummy Flags
  public mostRecentObject: any;
  public vslDummyNullFlag: boolean = true;
  public hideExtendDummyorVesselName: boolean = true;
  public isExtendedToDummyVesselName: any;
  public twoCombineFixArr: any;
  public combineFixArr: any;
  public vslOperatorCodeID: any;
  public updateLinkedDummy: boolean;
  public fixPkidVal: any;
  public fixEstRedelivDate: any;
  public depPosition: any;

  public depStringID: any;
  public vesselCurrentDeploymentExtend: any;
  constructor(
    private vesselsService: VesselsService,
    private portsService: PortService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    private auth: AuthService,
    private colorConfigService: ColorConfigService,
    public vesselDropdowns: VesselDropdownsService,
    private ft: FeatureToggleService,
    private spinner: NgxSpinnerService
  ) {
    if (route.parent) {
      route.parent.data.subscribe(({ details }) => {
        this.vesselDetail = details;
      });
    }

    this.id = this.route.snapshot.paramMap.get('id');

    this.isNotFullView$ = this.breakpointObserver
      .observe(['(max-width:1349px)'])
      .pipe(distinctUntilChanged())
      .pipe(
        map((result) => {
          return result.matches;
        })
      );
    this.vesselTeuTypes$ = this.vesselDropdowns.getVesselTypes();
    const ftEnabled = this.ft.isFtEnabled('CII_NEW_FIELDS');
  }

  /**
   * onInit life-cycle hook
   */
  public ngOnInit() {
    this.LoadVesselDeploymentLines();
    // this.portsService.sendCount.subscribe((res: any) => {
    //   // console.log'this.portsService.sendCount');
    //   // console.logres);
    //   this.arrValue['countid'] = res['vesselCodeCount'];
    //   this.countIdCheck = this.arrValue['countid'] > 1 ? false : true;
    //   this.blockNum = res.allDeployments;
    // });
    if (this.auth.BYPASS_AUTHENTICATION) {
      this.isExtendDummyAuth = true;
    } else {
      this.isExtendDummyAuth =
        this.auth.userProfile.roles.includes('L3') || this.auth.userProfile.roles.includes('Deployment');
    }
    this.loading = true;
    this.showMoreVesselOwner = true;
    this.showMoreVesselContact = true;
    this.isVsOverwUpdateAuth = this.auth.isAuthorised(this.auth.roles, 'Vessels', 'update');
    this.getVesselSize();
    this.vesselsService
      .getVesselFixtures(this.id)
      .pipe(distinctUntilChanged())
      .subscribe((vesselFixtures: Response) => {
        this.vesselCurrentFixture =
          vesselFixtures['currentFixture'] && vesselFixtures['currentFixture'].length
            ? vesselFixtures['currentFixture']
            : null;

        this.vesselUpcomingFixture =
          vesselFixtures['upcomingFixture'] && vesselFixtures['upcomingFixture'].length
            ? vesselFixtures['upcomingFixture']
            : null;

        this.vesselPreviousFixtures =
          vesselFixtures['previousFixture'] && vesselFixtures['previousFixture'].length
            ? vesselFixtures['previousFixture']
            : null;
        
            this.vesselITCFixtures = vesselFixtures['itcFixture'] && vesselFixtures['itcFixture'].length
            ? vesselFixtures['itcFixture']
            : null;
        this.colorConfigService.getColorConfigForCharterRequestRates().subscribe((charterRequestRatesColorConfig) => {
          this.charterRequestRatesColorConfig = charterRequestRatesColorConfig;
        });
        // Extend To Dummy Logic Implementation
        if (
          this.vesselCurrentFixture === null &&
          this.vesselUpcomingFixture === null &&
          this.vesselPreviousFixtures === null
        ) {
          this.noFixtureMsg = true;
          // this.combineFixArr = null;
          this.vslDummyNullFlag = false;
          this.hideExtendDummyorVesselName = true;
        } else {
          // if (this.vesselCurrentFixture === null && this.vesselUpcomingFixture === null) {
          //   this.vslDummyNullFlag = false;
          //   this.hideExtendDummyorVesselName = true;

          // }
          // else {

          const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
          this.combineFixArr = concat(
            this.vesselCurrentFixture,
            this.vesselUpcomingFixture,
            this.vesselPreviousFixtures
          );

          // Passing combine fix array to vessel-redesign comp
          this.vesselsService.setDataCombineArr(this.combineFixArr);
          if (this.combineFixArr.length > 0) {
            let fixPKIDForNull; // Id to pick when vslDummy id is null and extendable to dummy is true
            let fixPKIDForNotNull; // Id to pick when vslDummy id is not null and vvsldummyname is there.
            let fixEstRedelivDateforNull; // Id to pick when vslDummy id is null and extendable to dummy is true
            let fixEstRedelivDateforNotNull; // Id to pick when vslDummy id is not null and vvsldummyname is there.
            let fixPKIDForNotNullVesselName;
            let vslOperatorCodeIDforNull;
            let vslOperatorCodeIDforNotNull;
            this.combineFixArr.forEach((objItem, index) => {
              if (objItem !== null) {
                this.vslDummyNullFlag = this.vslDummyNullFlag ? objItem.vslExtendedDummyId === null : false;
                this.hideExtendDummyorVesselName = this.hideExtendDummyorVesselName
                  ? objItem.vslExtendedDummyId === null && !objItem.isExtendableToDummy
                  : false;
                if (objItem.vslExtendedDummyId === null && objItem.isExtendableToDummy && !fixPKIDForNull) {
                  fixPKIDForNull = objItem.fixPKID;
                  fixEstRedelivDateforNull = objItem.fixEstRedelivDate;
                  vslOperatorCodeIDforNull = objItem.vslOperatorCodeID;
                  this.fixEstRedelivDate = objItem.fixEstRedelivDate;
                  this.vslOperatorCodeID = objItem.vslOperatorCodeID;
                }

                if (
                  objItem.vslExtendedDummyId !== null &&
                  !objItem.isExtendableToDummy &&
                  objItem.vslExtendedDummyName.length > 0
                ) {
                  fixPKIDForNotNull = objItem.fixPKID;
                  fixPKIDForNotNullVesselName = objItem.vslExtendedDummyName;
                  fixEstRedelivDateforNotNull = objItem.fixEstRedelivDate;
                  vslOperatorCodeIDforNotNull = objItem.vslOperatorCodeID;
                  this.fixEstRedelivDate = objItem.fixEstRedelivDate;
                  this.vslOperatorCodeID = objItem.vslOperatorCodeID;
                }
              }
            });
            if (this.vslDummyNullFlag) {
              this.fixPkidVal = fixPKIDForNull;
              this.fixEstRedelivDate = fixEstRedelivDateforNull;
              this.vslOperatorCodeID = vslOperatorCodeIDforNull;
            } else {
              this.fixPkidVal = fixPKIDForNotNull;
              this.fixEstRedelivDate = fixEstRedelivDateforNotNull;
              this.isExtendedToDummyVesselName = fixPKIDForNotNullVesselName;
              this.vslOperatorCodeID = vslOperatorCodeIDforNotNull;
            }
          }
          // }
        }
      });
    // Commented below Code as its duplicated in vessel-redesign-deplyment
    // todo : after validating remove below code
    // this.vesselsService
    //   .getVesselDeployments(this.id)
    //   .pipe(distinctUntilChanged())
    //   .subscribe((vesselDeployments: Response) => {
    //     this.vesselCurrentDeploymentExtend =
    //       vesselDeployments['currentDeployment'] && vesselDeployments['currentDeployment'].length
    //         ? vesselDeployments['currentDeployment']
    //         : null;
    //     this.vesselCurrentDeployment =
    //       vesselDeployments['currentDeployment'] && vesselDeployments['currentDeployment'].length
    //         ? vesselDeployments['currentDeployment'][0]
    //         : null;
    //     if (this.vesselCurrentDeployment != null) {
    //       const valCurrent = this.vesselCurrentDeployment.maerskAllocationOnServiceDto;
    //       if (valCurrent != null) {
    //         if (
    //           valCurrent.mlmtCap === 0 ||
    //           valCurrent.mlTeuCap === 0 ||
    //           valCurrent.totalMTCap === 0 ||
    //           valCurrent.totalTeuCap === 0
    //         ) {
    //           this.vsaFlagCur = ' ';
    //         } else {
    //           const teuPercent = Number((valCurrent.mlTeuCap / valCurrent.totalTeuCap) * 100).toFixed(0);
    //           const mlPercent = Number((valCurrent.mlmtCap / valCurrent.totalMTCap) * 100).toFixed(0);
    //           if (+teuPercent < 100 || +mlPercent < 100) {
    //             this.vsaFlagCur = '(VSA) ';
    //           } else {
    //             this.vsaFlagCur = ' ';
    //           }
    //         }
    //       }
    //     }

    //     this.vesselUpcomingDeployment =
    //       vesselDeployments['upcomingDeployment'] && vesselDeployments['upcomingDeployment'].length
    //         ? vesselDeployments['upcomingDeployment']
    //         : null;
    //     if (this.vesselUpcomingDeployment != null) {
    //       this.vesselUpcomingDeployment.map((e) => {
    //         const valUpcomming = e.maerskAllocationOnServiceDto;
    //         if (valUpcomming != null) {
    //           if (
    //             valUpcomming.mlmtCap === 0 ||
    //             valUpcomming.mlTeuCap === 0 ||
    //             valUpcomming.totalMTCap === 0 ||
    //             valUpcomming.totalTeuCap === 0
    //           ) {
    //             e.vsaFlagUp = ' ';
    //           } else {
    //             const teuPercent = Number((valUpcomming.mlTeuCap / valUpcomming.totalTeuCap) * 100).toFixed(0);
    //             const mlPercent = Number((valUpcomming.mlmtCap / valUpcomming.totalMTCap) * 100).toFixed(0);
    //             if (+teuPercent < 100 || +mlPercent < 100) {
    //               e.vsaFlagUp = '(VSA) ';
    //             } else {
    //               e.vsaFlagUp = ' ';
    //             }
    //           }
    //         }
    //       });
    //     }
    //     this.vesselPreviousDeployments =
    //       vesselDeployments['previousDeployment'] && vesselDeployments['previousDeployment'].length
    //         ? vesselDeployments['previousDeployment']
    //         : null;

    //     // Extend Dummy Logic Implementation To Fetch Service and Position
    //     if (this.vesselCurrentDeployment === null && this.vesselUpcomingDeployment === null) {
    //       const latestDateItem = null;
    //     } else {
    //       const latestDateItem = this.getLastDeploymentLineFromCurrentOrUpcominglines();
    //       this.depPosition = latestDateItem.depPosition;
    //       this.depStringID = latestDateItem.depStringID;
    //     }
    //   });
  }

  public ngOnDestroy() {}

  public getLastDeploymentLineFromCurrentOrUpcominglines() {
    if (this.vesselUpcomingDeployment != null || this.vesselCurrentDeploymentExtend != null) {
      return this.vesselUpcomingDeployment != null
        ? Array.isArray(this.vesselUpcomingDeployment)
          ? this.vesselUpcomingDeployment[this.vesselUpcomingDeployment.length - 1]
          : this.vesselUpcomingDeployment
        : this.vesselCurrentDeploymentExtend != null
        ? Array.isArray(this.vesselCurrentDeploymentExtend)
          ? this.vesselCurrentDeploymentExtend[this.vesselCurrentDeploymentExtend.length - 1]
          : this.vesselCurrentDeploymentExtend
        : null;
    }
  }
  public navigateToDummy(fixPkidVal, isExtendedTrue) {
    // Setting data to fetch pkid on save.
    // Extend To Dummy Set Item
    // this.getEarlyRedelDate();
    localStorage.setItem('this.vesselDetail.vslVesselName', this.vesselDetail.vslIMONumber);
    localStorage.setItem('this.vesselDetail.vslVesselName', this.vesselDetail.vslVesselName);
    localStorage.setItem('this.vesselDetail.vslVesselType', this.vesselDetail.vslVesselType);
    localStorage.setItem('this.vesselDetail.vesselTeu', this.vesselDetail.vesselTeu);
    localStorage.setItem('this.vesselDetail.vslTeuNominal', this.vesselDetail.vslTeuNominal);
    localStorage.setItem('this.vesselDetail.vslTonCenCap', this.vesselDetail.vslTonCenCap);
    localStorage.setItem('this.vesselDetail.vslGT', this.vesselDetail.vslGT);
    localStorage.setItem('this.vesselDetail.vslDWT', this.vesselDetail.vslDWT);
    localStorage.setItem('this.vesselDetail.vslMaxSpeed', this.vesselDetail.vslMaxSpeed);
    localStorage.setItem('this.vesselDetail.vslTeuReefer', this.vesselDetail.vslTeuReefer);
    localStorage.setItem('this.fixEstRedelivDate', this.fixEstRedelivDate);
    localStorage.setItem('this.vslOperatorCodeID', this.vslOperatorCodeID);
    localStorage.setItem('this.depPosition', this.depPosition);
    localStorage.setItem('this.depStringID', this.depStringID);
    localStorage.setItem('fixPkidVal', fixPkidVal);
    localStorage.setItem('dontShowDelete', 'true');
    if (!isExtendedTrue) {
      window.open('../../vessels/extenddummy/overview/' + this.id);
    } else {
      let idOfDummyOnExtension;
      const fixtureUsedToExtend = this.combineFixArr.filter((element) => element.fixPKID === fixPkidVal);
      if (fixtureUsedToExtend.length > 0) {
        idOfDummyOnExtension = fixtureUsedToExtend[0].vslExtendedDummyId;
      }
      window.open('../../vessels/dummy/overview/' + idOfDummyOnExtension);
    }
  }

  public navigateToLinkedDummy(fixPkidVal) {
    // Setting data to fetch pkid on save.
    // linked Dummy Set Item
    localStorage.setItem('this.fixEstRedelivDate', this.mostRecentObject.fixEstRedelivDate);
    localStorage.setItem('this.vslOperatorCodeID', this.mostRecentObject.vslOperatorCodeID);
    localStorage.setItem('fixPkidVal', fixPkidVal);
    localStorage.setItem('this.updateLinkedDummy', 'true');

    let idOfDummyOnExtension;
    const fixtureUsedToExtend = this.combineFixArr.filter((element) => element.fixPKID === fixPkidVal);
    if (fixtureUsedToExtend.length > 0) {
      idOfDummyOnExtension = fixtureUsedToExtend[0].vslExtendedDummyId;
    }
    window.open('../../vessels/dummy/overview/' + idOfDummyOnExtension);
  }

  // get earliest redeliverydate

  // public getEarlyRedelDate() {
  //   var compareDateArray = [];

  //   if (this.vesselCurrentFixture !== null) {
  //     var mostCurrRecentDate = new Date(Math.min.apply(null, this.vesselCurrentFixture.map(e => {
  //       return new Date(e.fixEstRedelivDate);
  //     })));

  //     var mostCurrRecentObject = this.vesselCurrentFixture.filter(e => {
  //       var d = new Date(e.fixEstRedelivDate);
  //       return d.getTime() == mostCurrRecentDate.getTime();
  //     })[0];
  //     compareDateArray.push(mostCurrRecentObject);

  //   }

  //   if (this.vesselUpcomingFixture !== null) {

  //     var mostUpcRecentDate = new Date(Math.min.apply(null, this.vesselUpcomingFixture.map(e => {
  //       return new Date(e.fixEstRedelivDate);
  //     })));

  //     var mostUpcRecentObject = this.vesselUpcomingFixture.filter(e => {
  //       var d = new Date(e.fixEstRedelivDate);
  //       return d.getTime() == mostUpcRecentDate.getTime();
  //     })[0];
  //     compareDateArray.push(mostUpcRecentObject);

  //   }

  //   if (this.vesselCurrentFixture == null && this.vesselUpcomingFixture == null && this.vesselPreviousFixtures !== null) {

  //     var mostPrevRecentDate = new Date(Math.max.apply(null, this.vesselPreviousFixtures.map(e => {
  //       return new Date(e.fixEstRedelivDate);
  //     })));

  //     var mostPrevRecentObject = this.vesselPreviousFixtures.filter(e => {
  //       var d = new Date(e.fixEstRedelivDate);
  //       return d.getTime() == mostPrevRecentDate.getTime();
  //     })[0];
  //     compareDateArray.push(mostPrevRecentObject);

  //   }

  //   var mostRecentDate = new Date(Math.min.apply(null, compareDateArray.map(e => {
  //     return new Date(e.fixEstRedelivDate);
  //   })));

  //   this.mostRecentObject = compareDateArray.filter(e => {
  //     var d = new Date(e.fixEstRedelivDate);
  //     return d.getTime() == mostRecentDate.getTime();
  //   })[0];

  // }

  public ngAfterViewChecked() {
    setTimeout(() => {
      if (this.contactItems.length > 3) {
        this.showMoreContactsVisible = true;
        this.toggleMoreVesselContact(false);
      } else {
        this.showMoreContactsVisible = false;
      }
      if (this.ownerItems.length > 3) {
        this.showMoreOwnersVisible = true;
        this.toggleMoreVesselOwner(false);
      } else {
        this.showMoreOwnersVisible = false;
      }
    }, 100);
  }
  public getPosition() {
    if (this.previousDeploymentID) {
      this.vesselsService
        .getDeploymentDetails(this.previousDeploymentID)
        .subscribe((previousDeploymentDetails: Response) => {
          this.previousDeploymentDetails = previousDeploymentDetails;
          this.previousDeploymentIdPosition = this.previousDeploymentDetails.depposition;
        });
    }
  }
  public charterRequestRatesColorSetter(row) {
    if (row.fixChrtPrice && row.marketRate) {
      const diff = row.fixChrtPrice - row.marketRate;
      if (this.charterRequestRatesColorConfig) {
        if (diff >= this.charterRequestRatesColorConfig.mediumValue) {
          return this[this.charterRequestRatesColorConfig.mediumValueColor];
        } else {
          return this[this.charterRequestRatesColorConfig.lowValueColor];
        }
      }
    } else if (!row.fixChrtPrice && row.fixtureRates.length && row.fixtureRates[0].frValue) {
      const diff = row.fixtureRates[0].frValue - row.marketRate;
      if (this.charterRequestRatesColorConfig) {
        if (diff >= this.charterRequestRatesColorConfig.mediumValue) {
          return this[this.charterRequestRatesColorConfig.mediumValueColor];
        } else {
          return this[this.charterRequestRatesColorConfig.lowValueColor];
        }
      }
    }
  }

  public changeLogPopup() {
    window.open(
      '1/changelog/' + this.id,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=920,height=550'
    );
  }

  public postedHandler(event) {
    if (event) {
      this.router
        .navigateByUrl('/vessels', { skipLocationChange: true })
        .then(() => this.router.navigate(['/vessels/' + this.id]));
    }
  }

  public toggleMoreVesselOwner = (click: boolean) => {
    if (click) {
      this.showMoreOwners = !this.showMoreOwners;
    }
    this.ownerItems.map((item, key) => {
      if (key > 2 && !this.showMoreOwners) {
        item.nativeElement.classList.add('hide');
      } else {
        item.nativeElement.classList.remove('hide');
      }
      return true;
    });
  };

  public toggleMoreVesselContact = (click: boolean) => {
    if (click) {
      this.showMoreContacts = !this.showMoreContacts;
    }

    this.contactItems.map((item, key) => {
      if (key > 2 && !this.showMoreContacts) {
        item.nativeElement.classList.add('hide');
      } else {
        item.nativeElement.classList.remove('hide');
      }
      return true;
    });
  };

  public showMoreOwnersSwitch() {
    this.showMoreComercialOwner = false;
    this.showMoreTechOwner = false;
    this.showMoreCrewOwner = false;
    this.showMoreManagingOwner = false;
    this.showMoreBrokerOwner = false;
    this.showMoreHeadOwner = false;
  }

  /** */
  public createNewDeploymentForm(depValues: any = null) {
    if (!depValues) {
      this.vesselDeploymentFrmGrp = this.fb.group({
        depPKID: 0,
        depVesselID: this.id,
        depStringID: null,
        depPosition: null,
        depStartPortID: null,
        depEndPortID: null,
        depBeginDate: moment(this.previousDeploymentEndDate),
        depBeginTime: isValidDate(this.previousDeploymentEndDate)
          ? new DatePipe('en-US').transform(this.previousDeploymentEndDate, 'HH:mm')
          : time[0],
        depEndDate: null,
        depEndTime: time[0],
        depReasonCodeID: null,
        depComments: null,
        specialSerivceType: null,
        unemployedeType: null,
        layupType: null,
      });
    }
  }
  /** */
  // public populateServiceDetails(e) {
  //   this.showSpecialServicesFlag = false;
  //   this.showDetailsDiv = true;
  //   this.showLocationHidePort = false;
  //   this.showvesselDepSpclServiceTypes = false;
  //   this.showvesselDepUnemployedTypes = false;
  //   this.showvesselDepLayupTypes = false;
  //   this.dontShowServiceType = false;
  //   this.hideTrade = false;
  //   this.hideRoundTrip = false;
  //   this.showServicePosition = false;
  //   this.showScheduleDateError = false;
  //   this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(null);
  //   this.getPosition();
  //   if (e.isSpecial) {
  //     this.hideTrade = true;
  //     this.hideRoundTrip = true;
  //     if (
  //       e.strName.toLowerCase() === 'docking offservice' ||
  //       e.strName.toLowerCase() === 'unemployed' ||
  //       e.strName.toLowerCase() === 'off-service' ||
  //       e.strName.toLowerCase() === 'lay-up / unemployed tonnage 888'
  //     ) {
  //       this.showLocationHidePort = true;
  //     }
  //     if (
  //       e.strName.toLowerCase() === 'internal relet' ||
  //       e.strName.toLowerCase() === 'external relet' ||
  //       e.strName.toLowerCase() === 'empties positioning' ||
  //       e.strName.toLowerCase() === 'fleet positioning' ||
  //       e.strName.toLowerCase() === 'docking offservice'
  //     ) {
  //       this.showvesselDepSpclServiceTypes = true;
  //     }
  //     if (e.strName.toLowerCase() === 'docking offservice') {
  //       const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'docking');
  //       if (reasonObj) {
  //         this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
  //       }
  //     }
  //     if (e.strName.toLowerCase().includes('relet')) {
  //       const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'relet');
  //       if (reasonObj) {
  //         this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
  //       }
  //     }
  //     if (e.strName.toLowerCase() === 'empties positioning') {
  //       const reasonObj = this.vesselDeploymentReasons.filter(
  //         (o) => o.drReason.toLowerCase() === 'empties positioning'
  //       );
  //       if (reasonObj) {
  //         this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
  //       }
  //     }
  //     if (e.strName.toLowerCase() === 'lay-up / unemployed tonnage 888') {
  //       const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'layup');
  //       if (reasonObj) {
  //         this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
  //       }
  //     }
  //     if (e.strName.toLowerCase() === 'unemployed') {
  //       this.showvesselDepUnemployedTypes = true;
  //     }
  //     if (e.strName.toLowerCase() === 'off-service') {
  //       this.dontShowServiceType = true;
  //     }
  //     if (e.strName.toLowerCase() === 'lay-up / unemployed tonnage 888') {
  //       this.showvesselDepLayupTypes = true;
  //     }
  //     this.portsService.getPorts().subscribe((ports) => {
  //       this.vesselDeploymentStartPort = [];
  //       this.vesselDeploymentEndPort = [];
  //       this.vesselDeploymentStartPort = ports;
  //       this.vesselDeploymentEndPort = ports;
  //       this.vesselDeploymentStartPort = this.vesselDeploymentStartPort.map((o) => {
  //         return {
  //           schedulePortId: o.prtPKID,
  //           schedulePortName: o.prtPortName,
  //           scheduleDate: null,
  //           scheduleTime: null,
  //           scheduleType: null,
  //           position: null,
  //           portId: o.prtPKID,
  //         };
  //       });
  //       this.vesselDeploymentEndPort = this.vesselDeploymentEndPort.map((o) => {
  //         return {
  //           schedulePortId: o.prtPKID,
  //           schedulePortName: o.prtPortName,
  //           scheduleDate: null,
  //           scheduleTime: null,
  //           scheduleType: null,
  //           position: null,
  //           portId: o.prtPKID,
  //         };
  //       });
  //       if (this.previousDeploymentIdPosition > 0) {
  //         let startPortToPopulate: any;
  //         const startDateBeforePort = new Date(this.vesselDeploymentFrmGrp.controls.depBeginDate.value);
  //         startPortToPopulate = ports.portDateTime.filter((k) => startDateBeforePort <= k.scheduleDate);
  //       }
  //     });
  //   } else {
  //     this.roundTrip = e.strRoundTrip;
  //     this.trade = e.strTrade;
  //     this.showServicePosition = true;
  //     this.servicePositions = e.positions;
  //     this.vesselsService.getPortsByService(e.strPKID, this.previousDeploymentEndDate, null).subscribe(
  //       (response: any) => {
  //         this.allPortsByService = response;
  //         const patternMatched = this.validateRegExPosPattern();
  //         let positionSelected;

  //         if (patternMatched === true) {
  //           const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
  //           const xPos = extraPosValue.indexOf('x');
  //           xPos === 1
  //             ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
  //             : xPos === 2
  //             ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
  //             : (this.extraLoaderPosition = null);
  //         } else {
  //           positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
  //         }
  //         // End of code
  //         this.vesselDeploymentStartPort = [];
  //         this.vesselDeploymentEndPort = [];
  //         if (positionSelected && positionSelected > 0) {
  //           this.isVesslDeplyServiceDataPositionEnterd = true;
  //           this.vesselDeploymentStartPort = response.portDateTime.filter(
  //             (o) => o.scheduleType === 0 && o.position === positionSelected
  //           );
  //           this.vesselDeploymentEndPort = response.portDateTime.filter(
  //             (o) => o.scheduleType === -1 && o.position === positionSelected
  //           );
  //         } else {
  //           this.isVesslDeplyServiceDataPositionEnterd = false;
  //           this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.scheduleType === 0);
  //           this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.scheduleType === -1);
  //         }
  //         if (this.previousDeploymentEndDate !== null && positionSelected > 0) {
  //           let startPortToPopulate: any;
  //           const startDateBeforePort = new Date(this.vesselDeploymentFrmGrp.controls.depBeginDate.value);
  //           startPortToPopulate = response.portDateTime.filter((k) => positionSelected === k.position);
  //           if (startPortToPopulate.length) {
  //             this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
  //           }
  //           let endPortToPopulate: any;
  //           const endDateBeforePort = new Date(
  //             new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depEndDate.value, 'dd-MMM-yyyy')
  //           );
  //           if (endDateBeforePort) {
  //             endPortToPopulate = response.portDateTime.filter(
  //               (k) =>
  //                 positionSelected === k.position &&
  //                 endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
  //                 k.scheduleType === 0
  //             );
  //             if (endPortToPopulate.length) {
  //               this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(
  //                 endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
  //               );
  //             }
  //           }
  //         }
  //       },
  //       (errorResponse) => {
  //         this.showScheduleDateError = true;
  //         const msg = errorResponse.error.error;
  //         this.scheduleDateErrorMsg = msg;
  //       }
  //     );
  //   }
  // }
  /** */
  public cancelNew() {
    this.showError = false;
    this.showBeginDateError = false;
    this.showBeginTimeError = false;
    this.showEndDateError = false;
    this.showEndTimeError = false;
    this.endDateNotPresent = false;
    this.createVesselDeployment = false;
    this.typeNew = false;
    this.showDetailsDiv = false;
    this.showIncorrectDetailError = false;
    this.showScheduleDateError = false;
    this.vesselDeploymentFrmGrp.reset();
  }

  public validateRegExPosPattern() {
    return /^(?:(^([1-9][\d]*){1,2})+([xX])$)/g.test(this.vesselDeploymentFrmGrp.controls.depPosition.value);
  }

  public validateDepPosition() {
    this.depPositionExists = true;
    this.extraPosEntered = false;
    this.showError = false;
    const servicePositionList = [];
    this.servicePositions.map((item) => {
      servicePositionList.push(item.position);
    });
    const patternMatched = this.validateRegExPosPattern();
    if (patternMatched === true) {
      this.extraPosEntered = true;
      this.extraLoaderPosition = this.vesselDeploymentFrmGrp.controls.depPosition.value;
      this.extraLoaderPosition = this.extraLoaderPosition.toLowerCase();
      // For extra slice x from the input and check min max value validation
      const xPos = this.extraLoaderPosition.indexOf('x');
      xPos === 1
        ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
        : xPos === 2
        ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
        : (this.extraLoaderPosition = null);
      if (+this.extraLoaderPosition >= 0 && +this.extraLoaderPosition <= 99) {
        this.depPositionValid = true;
        this.validatePosExists(servicePositionList, this.extraLoaderPosition, true);
      } else {
        this.depPositionValid = false;
      }
    } else if (patternMatched === false) {
      if (
        +this.vesselDeploymentFrmGrp.controls.depPosition.value >= 0 &&
        +this.vesselDeploymentFrmGrp.controls.depPosition.value <= 99
      ) {
        this.depPositionValid = true;
        this.validatePosExists(servicePositionList, this.vesselDeploymentFrmGrp.controls.depPosition.value, false);
      } else {
        this.depPositionValid = false;
      }
    }
  }
  public validatePosExists(list, positionEntered, extra?) {
    this.validExtraPos = false;
    if (list.includes(+positionEntered) && extra === false) {
      this.depPositionExists = true;
    } else if (extra === false && (+positionEntered === 0 || +positionEntered === 31 || +positionEntered === 99)) {
      this.depPositionExists = true;
    } else if (list.includes(+positionEntered) && extra === true) {
      const specialPos = ['0', '31', '99'];
      if (specialPos.indexOf('' + positionEntered) === -1) {
        this.validExtraPos = true;
      } else {
        this.depPositionExists = false;
        this.showError = true;
      }
    } else {
      this.depPositionExists = false;
      this.showError = true;
    }
  }
  /** */
  public planNewDeployment() {
    this.createVesselDeployment = true;
    this.typeNew = true;
    if (this.typeNew) {
      this.createNewDeploymentForm();
    }
  }
  public validateNewDepObj() {
    this.showError = false;
    this.endDateNotPresent = false;
    this.showBeginDateError = false;
    this.showBeginTimeError = false;
    this.showEndDateError = false;
    this.showEndTimeError = false;
    this.showDatesInvalid = false;
    this.showRangeInvalid = false;
    if (!this.vesselDeploymentFrmGrp.valid || !this.depPositionValid) {
      this.showError = true;
      return;
    }
    if (!this.vesselDeploymentFrmGrp.valid || !this.depPositionExists) {
      this.showError = true;
      return;
    }
    if (this.showLocationHidePort) {
      if (this.vesselDeploymentFrmGrp.controls.depStartPortID.value === null) {
        const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
        const srviceObj = this.vesselDeploymentSpclServices.filter((o) => o.strPKID === selectedService);
        if (srviceObj) {
          if (srviceObj[0].strName.toLowerCase() === 'docking offservice') {
            this.showError = true;
            this.locationErrorMsg = 'Please indicate docking location & yard';
            return;
          }
        }
      }
    }
    if (this.showServicePosition) {
      if (this.vesselDeploymentFrmGrp.controls.depStartPortID.value === null) {
        const patternMatched = this.validateRegExPosPattern();
        let selectedPosition;

        if (patternMatched === true) {
          const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
          const xPos = extraPosValue.indexOf('x');
          xPos === 1
            ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
            : xPos === 2
            ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
            : (this.extraLoaderPosition = null);
          selectedPosition = this.extraLoaderPosition;
        } else {
          selectedPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
        }
        // End of code
        if (selectedPosition === -1 || selectedPosition === 0) {
          this.showError = true;
          this.locationErrorMsg = 'Please indicate idle/positioning port(s)';
          return;
        }
      }
    }
    if (this.vesselDeploymentFrmGrp.controls.depBeginDate.value !== null) {
      if (
        this.vesselDeploymentFrmGrp.controls.depBeginTime.value === null ||
        this.vesselDeploymentFrmGrp.controls.depBeginTime.value === ''
      ) {
        this.showBeginTimeError = true;
        return;
      }
    }
    if (
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value !== null &&
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value !== ''
    ) {
      if (this.vesselDeploymentFrmGrp.controls.depBeginDate.value === null) {
        this.showBeginDateError = true;
        return;
      }
    }
    if (this.vesselDeploymentFrmGrp.controls.depEndDate.value !== null) {
      if (
        this.vesselDeploymentFrmGrp.controls.depEndTime.value === null ||
        this.vesselDeploymentFrmGrp.controls.depEndTime.value === ''
      ) {
        this.showEndTimeError = true;
        return;
      }
    }
    if (
      this.vesselDeploymentFrmGrp.controls.depEndTime.value !== null &&
      this.vesselDeploymentFrmGrp.controls.depEndTime.value !== ''
    ) {
      if (this.vesselDeploymentFrmGrp.controls.depEndDate.value === null) {
        this.showEndDateError = true;
        return;
      }
    }

    const endDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depEndDate.value,
      this.vesselDeploymentFrmGrp.controls.depEndTime.value
    );
    const beginDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depBeginDate.value,
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value
    );
    if (!endDate) {
      this.showError = true;
      this.endDateNotPresent = true;
      return;
    }
    if (endDate < beginDate) {
      this.showError = true;
      this.showDatesInvalid = true;
      return;
    }
    if (!(this.vesselDetail.vslVesselType === 'Own')) {
      const depEndPortIdFromList = this.vesselDeploymentEndPort.find(
        (ep) => ep.schedulePortId === this.vesselDeploymentFrmGrp.controls.depEndPortID.value
      );
      if (depEndPortIdFromList) {
        const portIdFound = this.fixturePortsForRange.find((p) => p === depEndPortIdFromList.portId);
        if (!portIdFound) {
          this.showError = true;
          this.showRangeInvalid = true;
          return;
        }
      }
    }

    const endDateDeployment = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depEndDate.value,
      this.vesselDeploymentFrmGrp.controls.depEndTime.value
    );
    if (this.vesselDeploymentDropdowns.blackouts.length > 0) {
      this.vesselDeploymentDropdowns.blackouts.forEach((blackout) => {
        if (endDateDeployment >= blackout.fxbStartDate && endDateDeployment <= blackout.fxbEndDate) {
          this.blackoutViolationMsg =
            'Please note the Blackout period of ' +
            new DatePipe('en-US').transform(blackout.fxbStartDate, 'dd-MMM-yy') +
            '     to     ' +
            new DatePipe('en-US').transform(blackout.fxbEndDate, 'dd-MMM-yy') +
            '. Do you wish to edit or ignore.?';
          this.showBlackoutConfirmation();
        } else {
          this.saveNewDeployment();
        }
      });
    } else {
      this.saveNewDeployment();
    }
  }
  /** */
  public saveNewDeployment() {
    const postObj = this.vesselDeploymentFrmGrp.value;
    const isServiceSpcl = this.vesselDeploymentSpclServices.filter((servc) => postObj.depStringID === servc.strPKID);
    if (isServiceSpcl.length) {
      postObj.depSpecialServiceStartPortID = this.vesselDeploymentFrmGrp.controls.depStartPortID.value;
      postObj.depSpecialServiceEndPortID = this.vesselDeploymentFrmGrp.controls.depEndPortID.value;
      postObj.depStartPortID = null;
      postObj.depEndPortID = null;
    } else {
      postObj.depSpecialServiceStartPortID = null;
      postObj.depSpecialServiceEndPortID = null;
    }

    postObj.depBeginDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depBeginDate.value,
      this.vesselDeploymentFrmGrp.controls.depBeginTime.value
    );
    postObj.depEndDate = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depEndDate.value,
      this.vesselDeploymentFrmGrp.controls.depEndTime.value
    );
    postObj.depPKID = postObj.depPKID ? postObj.depPKID : 0;
    postObj.previousDeploymentId = this.previousDeploymentId;
    // Extra Position Exists then send flag true and depPosition without X.
    if (this.validExtraPos === true) {
      postObj.depPosition = this.extraLoaderPosition;
    }
    postObj.extraPosition = this.validExtraPos === true ? true : false;
    // End of code
    const postObjFinal = postObj;
    delete postObj.depBeginTime;
    delete postObj.depEndTime;
    this.vesselsService.saveVesselDeploymentNewVessel(postObjFinal).subscribe((res) => {
      if (res['isOverlapDeployment'] === true) {
        const extraPos = res['extraPosition'] ? 'X' : '';
        const warnMessage =
          res['currVesselName'] +
          ' is not inserted -' +
          res['deployedVessels'][0]['deployedVesselName'] +
          ' is already deployed from ' +
          new DatePipe('en-US').transform(res['deployedVessels'][0]['deployedVslBeginDate'], 'dd-MMM-yyyy') +
          ' to ' +
          new DatePipe('en-US').transform(res['deployedVessels'][0]['deployedVslEndDate'], 'dd-MMM-yyyy') +
          ' at position ' +
          res['depPosition'] +
          extraPos +
          '.' +
          ' Do you want to insert overlap deployment? ';
        this.incorrectDeploymentDetails = warnMessage;
        this.dialogRef = this.dialog.open(this.warningMessagetDialogue, {
          width: '500px',
          height: 'auto',
          panelClass: 'dialog-container',
          disableClose: true,
        });
        this.dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result) {
              this.sendVesselDeoplymentPostObject(postObjFinal);
            }
          });
      } else {
        this.sendVesselDeoplymentPostObject(postObjFinal);
      }
    });
  }

  public sendVesselDeoplymentPostObject(postObjFinal) {
    this.vesselsService.saveVesselDeploymentNew(postObjFinal).subscribe(
      (res) => {
        this.createVesselDeployment = false;
        this.typeNew = false;
        this.router
          .navigateByUrl('/vessels', { skipLocationChange: true })
          .then(() => this.router.navigate(['/vessels/' + this.id]));
      },
      (errorResponse) => {
        this.errorKeys = Object.keys(this.vesselDeploymentFrmGrp).filter((key) =>
          Object.keys(errorResponse.error).includes(key)
        );
        this.errorValues = this.errorKeys.map((errorKey) => errorResponse.error[errorKey]);
        if (errorResponse instanceof HttpErrorResponse) {
          const error = errorResponse.error && errorResponse.error['error'] ? errorResponse.error['error'] : null;
          if (error) {
            this.showIncorrectDetailError = true;
            this.incorrectDeploymentDetails = error;
          }
        }
      }
    );
  }

  /** */
  public showSpecialServices() {
    this.showSpecialServicesFlag = true;
  }
  /** */
  // public populateServiceControl(e) {
  //   this.vesselDeploymentFrmGrp.patchValue({
  //     depStringID: e.strPKID,
  //   });

  //   this.populateServiceDetails(e);
  // }
  // public populateReasonDetails(e) {
  //   this.showScheduleDateError = false;
  //   this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(null);
  //   this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(null);
  //   const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
  //   this.vesselsService.getPortsByService(selectedService, this.previousDeploymentEndDate, null).subscribe(
  //     (response: any) => {
  //       const patternMatched = this.validateRegExPosPattern();
  //       let positionSelected;

  //       if (patternMatched === true) {
  //         const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
  //         const xPos = extraPosValue.indexOf('x');
  //         xPos === 1
  //           ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
  //           : xPos === 2
  //           ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
  //           : (this.extraLoaderPosition = null);
  //         positionSelected = this.extraLoaderPosition;
  //       } else {
  //         positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
  //       }
  //       this.vesselDeploymentStartPort = [];
  //       this.vesselDeploymentEndPort = [];
  //       if (positionSelected && positionSelected > 0) {
  //         this.isVesslDeplyServiceDataPositionEnterd = true;
  //         this.vesselDeploymentStartPort = response.portDateTime.filter(
  //           (o) => o.scheduleType === 0 && o.position === positionSelected
  //         );
  //         this.vesselDeploymentEndPort = response.portDateTime.filter(
  //           (o) => o.scheduleType === -1 && o.position === positionSelected
  //         );
  //         let startPortToPopulate: any;
  //         const startDateBeforePort = new Date(
  //           new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depBeginDate.value, 'dd-MMM-yyyy')
  //         );
  //         startPortToPopulate = response.portDateTime.filter((k) => k.position === positionSelected);
  //         if (startPortToPopulate.length) {
  //           this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
  //         }
  //         let endPortToPopulate: any;
  //         const endDateBeforePort = new Date(
  //           new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depEndDate.value, 'dd-MMM-yyyy')
  //         );
  //         if (endDateBeforePort) {
  //           endPortToPopulate = response.portDateTime.filter(
  //             (k) =>
  //               positionSelected === k.position &&
  //               endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
  //               k.scheduleType === 0
  //           );
  //           if (endPortToPopulate.length) {
  //             this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(
  //               endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
  //             );
  //           }
  //         }
  //       } else {
  //         this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.scheduleType === 0);
  //         this.vesselDeploymentEndPort = response.portDateTime.filter((o) => o.scheduleType === -1);
  //         this.isVesslDeplyServiceDataPositionEnterd = false;
  //       }
  //     },
  //     (errorResponse) => {
  //       this.showScheduleDateError = true;
  //       const msg = errorResponse.error.error;
  //       this.scheduleDateErrorMsg = msg;
  //     }
  //   );
  //   if (e.position === -1) {
  //     const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'idle');
  //     this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
  //   }
  //   if (e.position === 0) {
  //     const reasonObj = this.vesselDeploymentReasons.filter((o) => o.drReason.toLowerCase() === 'positioning');
  //     this.vesselDeploymentFrmGrp.controls.depReasonCodeID.setValue(reasonObj[0].drpkID);
  //   }
  // }

  public ignoreAndSave() {
    this.showRangeInvalid = false;
    const endDateDeployment = dateTimeCompile(
      this.vesselDeploymentFrmGrp.controls.depEndDate.value,
      this.vesselDeploymentFrmGrp.controls.depEndTime.value
    );
    if (this.vesselDeploymentDropdowns.blackouts.length) {
      this.vesselDeploymentDropdowns.blackouts.forEach((blackout) => {
        if (endDateDeployment >= blackout.fxbStartDate && endDateDeployment <= blackout.fxbEndDate) {
          this.blackoutViolationMsg =
            'Please note the Blackout period of ' +
            new DatePipe('en-US').transform(blackout.fxbStartDate, 'dd-MMM-yy') +
            '     to     ' +
            new DatePipe('en-US').transform(blackout.fxbEndDate, 'dd-MMM-yy') +
            '. Do you wish to edit or ignore.?';
          this.showBlackoutConfirmation();
        } else {
          this.saveNewDeployment();
        }
      });
    } else {
      this.saveNewDeployment();
    }
  }
  /** */
  public editPort() {
    this.showRangeInvalid = false;
  }
  /** */
  public populateStartDate(e) {}

  // public startDateChng() {
  //   const strDate = moment(this.vesselDeploymentFrmGrp.controls.depBeginDate.value).format('YYYY-MM-DD');
  //   const strTime = this.vesselDeploymentFrmGrp.controls.depBeginTime.value;
  //   const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
  //   const selectedDateTime = strDate + 'T' + strTime + ':00';
  //   this.vesselsService.getPortsByService(selectedService, selectedDateTime, null).subscribe(
  //     (response: any) => {
  //       this.allPortsByService = response;
  //       // Logic for Extra position containing x or X
  //       const patternMatched = this.validateRegExPosPattern();
  //       let positionSelected;
  //       if (patternMatched === true) {
  //         const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
  //         const xPos = extraPosValue.indexOf('x');
  //         xPos === 1
  //           ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
  //           : xPos === 2
  //           ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
  //           : (this.extraLoaderPosition = null);
  //         positionSelected = this.extraLoaderPosition;
  //       } else {
  //         positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
  //       }
  //       this.vesselDeploymentStartPort = [];
  //       if (positionSelected && positionSelected > 0) {
  //         this.isVesslDeplyServiceDataPositionEnterd = true;
  //         this.vesselDeploymentStartPort = response.portDateTime.filter(
  //           (o) => o.scheduleType === 0 && o.position === positionSelected
  //         );
  //       } else {
  //         this.isVesslDeplyServiceDataPositionEnterd = false;
  //         this.vesselDeploymentStartPort = response.portDateTime.filter((o) => o.scheduleType === 0);
  //       }
  //       if (positionSelected > 0) {
  //         let startPortToPopulate: any;
  //         const startDateBeforePort = new Date(this.vesselDeploymentFrmGrp.controls.depBeginDate.value);
  //         startPortToPopulate = response.portDateTime.filter((k) => positionSelected === k.position);
  //         if (startPortToPopulate.length) {
  //           this.vesselDeploymentFrmGrp.controls.depStartPortID.setValue(startPortToPopulate[0].schedulePortId);
  //         }
  //       }
  //     },
  //     (errorResponse) => {
  //       this.showScheduleDateError = true;
  //       const msg = errorResponse.error.error;
  //       this.scheduleDateErrorMsg = msg;
  //     }
  //   );
  // }
  // public endDateChng() {
  //   const endDate = moment(this.vesselDeploymentFrmGrp.controls.depEndDate.value).format('YYYY-MM-DD');
  //   const endTime = this.vesselDeploymentFrmGrp.controls.depEndTime.value;
  //   const selectedService = this.vesselDeploymentFrmGrp.controls.depStringID.value;
  //   const selectedDateTime = endDate + 'T' + endTime + ':00';
  //   this.showScheduleDateError = false;
  //   // Logic for Extra position containing x or X
  //   const patternMatched = this.validateRegExPosPattern();
  //   let positionSelected;

  //   if (patternMatched === true) {
  //     const extraPosValue = this.vesselDeploymentFrmGrp.controls.depPosition.value.toLowerCase();
  //     const xPos = extraPosValue.indexOf('x');
  //     xPos === 1
  //       ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 1))
  //       : xPos === 2
  //       ? (this.extraLoaderPosition = +this.vesselDeploymentFrmGrp.controls.depPosition.value.slice(0, 2))
  //       : (this.extraLoaderPosition = null);
  //     positionSelected = this.extraLoaderPosition;
  //   } else {
  //     positionSelected = +this.vesselDeploymentFrmGrp.controls.depPosition.value;
  //   }
  //   this.vesselDeploymentEndPort = [];
  //   if (positionSelected && positionSelected > 0) {
  //     this.isVesslDeplyServiceDataPositionEnterd = true;
  //     this.vesselDeploymentEndPort = this.allPortsByService.portDateTime.filter(
  //       (o) => o.scheduleType === -1 && o.position === positionSelected
  //     );
  //     if (this.vesselDeploymentEndPort.length === 0) {
  //       this.vesselDeploymentEndPort = [];
  //       this.showScheduleDateError = true;
  //       const msg = 'Schedules do not exist for the selected end date ';
  //       this.scheduleDateErrorMsg = msg;
  //     }
  //   } else {
  //     this.isVesslDeplyServiceDataPositionEnterd = false;
  //     this.vesselDeploymentEndPort = this.allPortsByService.portDateTime.filter((o) => o.scheduleType === -1);
  //   }
  //   if (positionSelected > 0) {
  //     let endPortToPopulate: any;
  //     const endDateBeforePort = new Date(
  //       new DatePipe('en-US').transform(this.vesselDeploymentFrmGrp.controls.depEndDate.value, 'dd-MMM-yyyy')
  //     );
  //     endPortToPopulate = this.allPortsByService.portDateTime.filter(
  //       (k) =>
  //         positionSelected === k.position &&
  //         endDateBeforePort >= new Date(new DatePipe('en-US').transform(k.scheduleDate, 'dd-MMM-yyyy')) &&
  //         k.scheduleType === 0
  //     );
  //     if (endPortToPopulate.length) {
  //       this.vesselDeploymentFrmGrp.controls.depEndPortID.setValue(
  //         endPortToPopulate[endPortToPopulate.length - 1].schedulePortId
  //       );
  //     }
  //   }
  // }
  /** */
  public populateEndDate(e) {}
  public getVesselSize() {
    this.vesselTeuTypes$.subscribe((data) => {
      this.vesselDropdowns.vesselTeuName = '';
      this.vesselDropdowns.vesselTeuID = '';
      const x = { ...this.vesselDetail };
      this.vesselDropdowns.vesselTeuName =
        data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuName !==
        undefined
          ? data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuName
          : null;

      this.vesselDropdowns.vesselTeuID =
        data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID !==
        undefined
          ? data.find((e) => x.vslTeuNominal >= e.vesselTeuFrom && x.vslTeuNominal <= e.vesselTeuTo).vesselTeuID
          : null;
    });
  }
  public LoadVesselDeploymentLines() {
    this.spinner.show();
    this.vesselsService
      .getVesselDeployments(this.id)
      .pipe(take(1), distinctUntilChanged())
      .subscribe(async (vesselDeployments: Response) => {
        if (vesselDeployments) {
          this.arrValue['countid'] = vesselDeployments['vesselCodeCount'];
          this.countIdCheck = this.arrValue['countid'] > 1 ? false : true;
          vesselDeployments[`allDeployments`].forEach((element) => {
            if (this.countIdCheck) {
              element[`isExpanded`] = true;
            } else {
              element[`isExpanded`] = element.vcStatus === 'Active' ? true : false;
            }
          });

          this.blockNum = vesselDeployments[`allDeployments`];

          // vesselDeployments[`allDeployments`].forEach((element) => {});
          // console.log`LoadVesselDeploymentLines Overview -${this.countIdCheck}`);
          // console.logthis.blockNum);
          this.spinner.hide();
        }
      });
  }
  public ShowExpandView(loop) {
    loop[`isExpanded`] = !loop.isExpanded;
  }
  private showBlackoutConfirmation() {
    this.dialogRef = this.dialog.open(this.editBlackout, {
      width: '500px',
      panelClass: 'fixture-overlay-container',
      disableClose: true,
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.saveNewDeployment();
        }
      });
  }
}
